<template>
  <div class="mine_video" :id="'video-' + account">
    <img
      src="@/assets/images/home/noData/no_connect_police.png"
      alt=""
      v-if="type == ''"
      style="height: 100%"
    />
    <img
      src="@/assets/images/home/noData/no_camera.png"
      alt=""
      v-if="$store.state.conversation.isShowCloseMyCamera || type == 1"
      style="height: 100%"
      class="img_div"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      account: "",
      type: "",
    };
  },
  computed: {
    ...mapState({
      isOpenCamera: (state) => state.conversation.setCamera,
    }),
  },
  methods: {
    toggleVideo() {
      this.$trtcCalling.startRemoteView({
        userID: this.account,
        videoViewDomID: "video-" + this.account,
      });
    },
  },
  created() {
    this.account = sessionStorage.getItem("userId");
  },
  mounted() {
    this.$bus.$on("openMyVideo", () => {
      if (+this.type === 0) {
        this.$trtcCalling
          .startLocalView({
            userID: this.account,
            videoViewDomID: "video-" + this.account,
          })
          .then((res) => {
            setTimeout(() => {
              if (!localStorage.getItem("openCamera")) {
                this.$trtcCalling.openCamera();
                this.$store.commit("setShowCloseMyCamera", false);
              } else {
                this.$trtcCalling.closeCamera();
                this.$store.commit("setShowCloseMyCamera", true);
              }
            });
          },2000);
      }
    });
    this.$bus.$on("getmyVideoData", (item) => {
      this.type = item.log.type;
    });
    this.$bus.$on("clearmyVideoData", () => {
      this.type = "";
      this.$store.commit("setShowCloseMyCamera", false);
    });
  },
  destroyed() {
    this.$bus.$off("openMyVideo");
    this.$bus.$off("getmyVideoData");
    this.$bus.$off("clearmyVideoData");
  },
};
</script>

<style  lang="scss" scoped>
.mine_video {
  margin-top: 2vh;
  width: 100%;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: #282d3a;
  position: relative;
  .img_div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
  }
}
.bg_mine_video {
  background: #fff !important;
}
</style>