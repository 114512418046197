<template>
  <div class="message_list">
    <div
      class="message_item"
      v-for="(message, index) in currentMessageList"
      :key="index"
    >
      <!-- 时间显示规则 -->
      <div class="message_time" v-if="index === 0">
        {{ message.time | getTime }}
      </div>
      <div
        class="message_time"
        v-else-if="message.time - currentMessageList[index - 1].time >= 60"
      >
        {{ message.time | getTime }}
      </div>
      <!-- 聊天单条信息 -->
      <div class="message_body" :class="{ right: message.flow == 'out' }">
        <!-- 聊天头像 -->
        <div class="avator_img">
          <img
            :src="[
              message.flow == 'out'
                ? require('@/assets/images/home/chat/chat_left.png')
                : require('@/assets/images/home/chat/chat_right.png'),
            ]"
            alt=""
            class="avator"
          />
        </div>
        <!-- 聊天信息 -->
        <div class="message_div">
          <div class="message_box" :class="{ right: message.flow == 'out' }">
            <div
              class="message"
              :class="{ message_right: message.flow == 'out' }"
              v-html="message.payload.text"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getTime } from "@/common/date";
export default {
  props: {
    currentMessageList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      startTime: 0,
      qiniu_url: "",
    };
  },
  filters: {
    getTime(timer) {
      return getTime(timer * 1000);
    },
  },
};
</script>

<style  lang="scss" scoped>
.message_list {
  width: 100%;
  padding-top: 5px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  transition: 0.5s;

  .message_item {
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    .message_time {
      height: 30px;
      font-size: 12px;
      font-weight: 400;
      color: #b3b3b3;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .message_body {
      display: flex;
    }
    .avator_img {
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      .avator {
        width: 24px;
        height: 24px;
      }
    }
    .message_div {
      flex: 1;
      display: flex;
      flex-direction: column;
      .message_box {
        margin-top: 5px;
        display: flex;
        .message {
          display: inline-block;
          padding: 5px;
          max-width: 300px;
          background: #f9f9f9;
          border-radius: 0px 5px 5px 5px;
          font-size: 14px;
          font-family: Source Han Sans CN, Source Han Sans CN-Regular;
          font-weight: 400;
          color: #707070;
          word-wrap: break-word;
          word-break: break-all;
        }
      }
    }
  }
}
.right {
  display: flex;
  flex-direction: row-reverse;
}

</style>