<template>
  <div class="alarm_call">
    <img src="@/assets/images/index/left.png" alt="" class="left_img hidden-sm-and-down">
    <div class="header">欢迎使用智慧报警坐席系统</div>
    <div class="body">
      <div class="police">
        <img src="@/assets/images/index/center.png" alt="" />
      </div>
    </div>
    <img src="@/assets/images/index/right.png" alt="" class="right_img hidden-sm-and-down">
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.alarm_call {
  margin-top: 15px;
  height: 60vh;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 4px 30px 0px rgba(244, 114, 93, 0.1);
  position: relative;
  .left_img{
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .right_img{
    position: absolute;
    top: 0;
    right: 0;
  }
  .header {
    height: 75px;
    line-height: 75px;
    font-size: 36px;
    font-weight: 500;
    text-align: center;
    color: #707070;
  }
  .body {
    height: 480px;
    .police {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 523px;
        height: 418px;
      }
    }
  }
}
</style>