// 时间格式转换 返回 (年-月-日 时:分:秒)
function timeFormat(time) {
	let date = new Date(time);
	let year = date.getFullYear();
	let month = date.getMonth();
	let day = date.getDate();
	let h = date.getHours();
	let m = date.getMinutes();
	let s = date.getSeconds()
	return year + '-' + haveZero(month+1) + '-' + haveZero(day) + ' ' + haveZero(h) + ':' + haveZero(m) + ':' + haveZero(s);
}

// 时间格式转换 返回 (年-月-日 时: 分)
function timeSf(time) {
	let date = new Date(time);
	let year = date.getFullYear();
	let month = date.getMonth();
	let day = date.getDate();
	let h = date.getHours();
	let m = date.getMinutes();
	return year + '-' + haveZero(month + 1) + '-' + haveZero(day) + ' ' + haveZero(h) + ':' + ' ' + haveZero(m);
}

// 时间格式转换 返回 (年/月/日/时:分)
function timex(time) {
	let date = new Date(time);
	let year = date.getFullYear();
	let month = date.getMonth();
	let day = date.getDate();
	let h = date.getHours();
	let m = date.getMinutes();
	return year + '/' + haveZero(month+1) + '/' + haveZero(day) + '/' + haveZero(h) + ':' + haveZero(m);
}

// 返回年-月-日
function getDate(data, splitor = '-') {
	let date = new Date(data);
	const year = date.getFullYear()
	const month = date.getMonth() 
	const day = date.getDate()
	const hour = date.getHours()
	const minute = date.getMinutes()
	const second = date.getSeconds()
	return `${year}${splitor}${haveZero(month+ 1)}${splitor}${haveZero(day)}`
}

// 返回年.月.日
function getDates(data, splitor = '.') {
	let date = new Date(data);
	const year = date.getFullYear()
	const month = date.getMonth() 
	const day = date.getDate()
	const hour = date.getHours()
	const minute = date.getMinutes()
	const second = date.getSeconds()
	return `${year}${splitor}${haveZero(month+ 1)}${splitor}${haveZero(day)}`
}

// 今天 --时/分
// 昨天 --时/分
// 前天 --时/分
// 年-月-日 时分
function getTime(time) {
	let date = new Date(time);
	const year = date.getFullYear();
	const month = date.getMonth();
	const hour = date.getHours()
	const minute = date.getMinutes()
	var today = new Date().getDate();
	var day = date.getDate();
	var result, offset;
	var days = parseInt((new Date().getTime() - date) / 86400000);
	offset = Math.abs(today - day);
	if (days < 3 && offset < 3) {
		if (offset === 0) {
			result = hour + ":" + haveZero(minute);
		} else if (offset === 1) {
			result = "昨天 " + hour + ":" + haveZero(minute);
		} else if (offset < 2) {
			result = "前天 " + hour + ":" + haveZero(minute);
		}
	} else {
		result = year + "-" + haveZero(month) + "-" + haveZero(day) + " " + haveZero(hour) + ":" + haveZero(minute);
	}
	return result
}

// 返回时分秒/时分
function transDate(time, withSecond = false) {
	let date = new Date(time);
	const year = date.getFullYear();
	const month = date.getMonth();
	const hour = date.getHours()
	const minute = date.getMinutes()
	const second = date.getSeconds()
	return withSecond ? `${haveZero(hour)}:${haveZero(minute)}:${haveZero(second)}` : `${hour}:${haveZero(minute)}`
}

function add0(m) { return m < 10 ? '0' + m : m }

function timeFormatX(timestamp) {
	//timestamp是整数，否则要parseInt转换,不会出现少个0的情况
	var time = new Date(timestamp);
	var year = time.getFullYear();
	var month = time.getMonth() + 1;
	var date = time.getDate();
	var hours = time.getHours();
	var minutes = time.getMinutes();
	var seconds = time.getSeconds();
	return year + '-' + add0(month) + '-' + add0(date) + ' ' + add0(hours) + ':' + add0(minutes) + ':' + add0(seconds);
}

// 时间格式转化 --- 判断数字见面是否补0
function haveZero(num) {
	return num < 10 ? '0' + num : num;
}

//获取星期几
function getWeekDate(time) {
	var week=['周日','周一','周二','周三','周四','周五','周六'];
	var times=new Date(time).getDay();
	return week[times];
}




// 获取本周时间
function getMonday(type, dates) {
	var now = new Date();
	var nowTime = now.getTime();
	var day = now.getDay();
	var longTime = 24 * 60 * 60 * 1000;
	var n = longTime * 7 * (dates || 0);
	if (type == "s") {
		var dd = nowTime - (day - 1) * longTime + n;
	};
	if (type == "e") {
		var dd = nowTime + (7 - day) * longTime + n;
	};
	dd = new Date(dd);
	var y = dd.getFullYear();
	var m = dd.getMonth() + 1;
	var d = dd.getDate();
	m = m < 10 ? "0" + m : m;
	d = d < 10 ? "0" + d : d;
	var day = y + "-" + m + "-" + d;
	if (type == 's') {
		return day//开始时间
	} else {
		return day//结束时间
	}
}
// 本月
function getMonth(type, months) {
	var d = new Date();
	var year = d.getFullYear();
	var month = d.getMonth() + 1;
	if (Math.abs(months) > 12) {
		months = months % 12;
	};
	if (months != 0) {
		if (month + months > 12) {
			year++;
			month = (month + months) % 12;
		} else if (month + months < 1) {
			year--;
			month = 12 + month + months;
		} else {
			month = month + months;
		};
	};
	month = month < 10 ? "0" + month : month;
	var date = d.getDate();
	var firstday = year + "-" + month + "-" + "01";
	var lastday = "";
	if (month == "01" || month == "03" || month == "05" || month == "07" || month == "08" || month == "10" ||
		month ==
		"12") {
		lastday = year + "-" + month + "-" + 31;
	} else if (month == "02") {
		if ((year % 4 == 0 && year % 100 != 0) || (year % 100 == 0 && year % 400 == 0)) {
			lastday = year + "-" + month + "-" + 29;
		} else {
			lastday = year + "-" + month + "-" + 28;
		};
	} else {
		lastday = year + "-" + month + "-" + 30;
	};
	var day = "";
	if (type == "s") {
		return firstday//开始时间
	} else {
		return lastday;//结束时间
		
	};
}
// 本年
function getYear(type, dates) {
	var dd = new Date();
	var n = dates || 0;
	var year = dd.getFullYear() + Number(n);
	if (type == "s") {
		var day = year + "-01-01";
	};
	if (type == "e") {
		var day = year + "-12-31";
	};
	if (!type) {
		var day = year + "-01-01/" + year + "-12-31";
	};
	if (type == "s") {
		return day//开始时间
	} else {
		return day;//结束时间
	}
}
//会话列表时间
function getSessionItemTime(time) {
	let date = new Date(time);
	const year = date.getFullYear();
	const month = date.getMonth();
	const hour = date.getHours()
	const minute = date.getMinutes()
	var today = new Date().getDate();
	var day = date.getDate();
	var result, offset;
	var days = parseInt((new Date().getTime() - date) / 86400000);
	offset = Math.abs(today - day);
	if (days < 3 && offset < 3) {
		if (offset === 0) {
			result = hour + ":" + haveZero(minute);
		} else if (offset === 1) {
			result = "昨天"
		} else if (offset === 2) {
			result = "前天"
		}
	} else {
		result = year + "/" + haveZero(month+1) + "/" + haveZero(day);
	}
	return result
}

export {
	timeFormat,
	timeSf,
	getDate,
	getDates,
	getTime,
	transDate,
	timex,
	timeFormatX,
	getMonday,
	getMonth,
	getYear,
	getSessionItemTime,
	getWeekDate
}