<template>
  <div class="header">
    <div class="header_left">
      <i
        :class="!isCollaspe ? 'el-icon-s-fold' : 'el-icon-s-unfold'"
        class="collaspe_logo"
        @click="collaspeClick"
      ></i
      >\
      <div class="header_title">可视化报警平台</div>
    </div>
    <div class="header_right" :class="{'end':this.$route.path!=='/home'}">
      <setting-card />
      <div class="company hidden-sm-and-down">
        <img src="@/assets/images/app/qie.png" alt="" >
        机企人
        </div>
    </div>
  </div>
</template>
<script>
import settingCard from "./settingCard/settingCard.vue";
export default {
  components: { settingCard },
  props: {
    isCollaspe: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchContent: "",
    };
  },
  mounted() {},
  methods: {
    //左侧导航栏收起
    collaspeClick() {
      this.$emit("collaspeChange");
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .header_left {
    display: flex;
    justify-content: center;
    align-items: center;
    .collaspe_logo {
      flex: 1;
      font-size: 25px;
      color: #f4725d;
    }
    .header_title {
      margin-left: 10px;
      font-size: 14px;
      font-weight: 400;
      color: #fff;
    }
  }
  .header_right {
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .company {
      font-size: 12px;
      font-weight: 400;
      color: #ffffff;
      display: flex;
      align-items: center;
      img{
        width: 30px;
        height: 30px;
      }
    }
  }
}
.end{
  justify-content: flex-end !important;
}
</style>