import TIM from 'tim-js-sdk';
import tim from '@/util/tim.js';
const state = {
	//当前聊天是否处于ready状态
	isIMReady: false,
	//当前会话对方信息
	currentConversation: {},
	//当前会话聊天消息
	currentMessageList: [],
	//
	nextReqMessageID: '',
	//
	log_id:'',
	//是否打开摄像头
	setCamera:false,
	//是否打开麦克风
	setMicMute:false,
	//是否显示关闭摄像头
	isShowCloseMyCamera:false,

}

const mutations = {
	setShowCloseMyCamera(state, isShowCloseMyCamera) {
		state.isShowCloseMyCamera = isShowCloseMyCamera
		
	},
	setCamera(state, openCamera) {
		state.setCamera = openCamera
		
	},
	setMicMute(state, setMicMute) {
		state.setMicMute = setMicMute
	},
	setLogId(state, log_id) {
		state.log_id = log_id
	},
	/**
	 * 登录修改IMSDK状态
	 * 调用时机：登录
	 * @param {Object} state
	 * @param {Message[]|Message} data
	 * @returns
	 */
	setTimReady(state, isSDKReady) {
		state.isSDKReady = typeof isSDKReady === 'undefined' ? !state.isSDKReady : isSDKReady
	},
	/**
	 * 将消息插入当前会话列表
	 * 调用时机：收/发消息事件触发时
	 * @param {Object} state
	 * @param {Message[]|Message} data
	 * @returns
	 */
	pushCurrentMessageList(state, data) {
		console.log(data);
		state.currentMessageList = [...state.currentMessageList, ...data]
		console.log(state.currentMessageList);
	},
	deleteMessage(state, data ){
		state.currentMessageList=[]
	},
	
}

const actions = {

}

export default {
	state,
	mutations,
	actions

}
