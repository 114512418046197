<template>
  <div class="pending">
    <img src="@/assets/images/index/pedding_bg.png" alt="" class="bg_img" />
    <div class="header">
      <div class="title_div">
        <img :src="pendingItem.icon" alt="" />
      </div>
    </div>
    <div class="body">
      <countTo
        :startVal="startVal"
        :endVal="pendingItem.sum"
        :duration="3000"
      ></countTo>
      <div class="title">
        {{ pendingItem.title }}
      </div>
    </div>
    <div class="footer">
      <div
        class="btn"
        :class="pendingItem.className"
        @click="btnClick(pendingItem.path)"
      >
        去处理
      </div>
    </div>
  </div>
</template>

<script>
import countTo from "vue-count-to";
export default {
  components: { countTo },
  props: {
    pendingItem: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      startVal: 0,
      endVal: 99,
    };
  },
  methods: {
    btnClick(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style lang="scss" scoped>
.pending {
  margin-top: 15px;
  height: 270px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 4px 30px 0px rgba(244, 114, 93, 0.1);
  position: relative;
  .bg_img {
    position: absolute;
    top: 0;
    right: 0;
  }
  .header {
    height: 100px;
    .title_div {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-top: 30px;
        width: 60px;
        height: 60px;
      }
    }
  }
  .body {
    height: 87px;
    text-align: center;
    font-size: 41px;
    font-weight: 700;
    color: #313131;
    .title {
      margin-top: 10px;
      margin-left: 2%;
      font-size: 18px;
      font-weight: 400;
      color: #707070;
      letter-spacing: 4px;
    }
  }
  .footer {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    .btn {
      width: 120px;
      height: 42px;
      line-height: 42px;
      text-align: center;

      border-radius: 5px;
      font-size: 18px;
      font-weight: 500;
      color: #ffffff;
      cursor: pointer;
    }
    .btn_green {
      background: #24d3b0;
    }
    .btn_red {
      background: #f4725d;
    }
    .btn_blue {
      background: #5ca4fe;
    }
  }
}
</style>