<template>
  <div class="chat_footer">
    <div class="input_div">
      <input type="text" placeholder="请输入回复内容..." v-model="message" @keydown.enter="sendClick" />
    </div>
    <div class="send_out">
      <div class="btn" @click="sendClick" >发送</div>
    </div>
  </div>
</template>

<script>
import { sendMsg } from "@/api/home/home";
import {setSmartrplog} from "@/api/alarmRecord/alarmRecord.js";
import { mapState } from "vuex";
export default {
  data() {
    return {
      message: "",
      currentConversationType: "C2C", //默认为1对1聊天模式
      helpPersonAccount: "", //对方的accountId
    };
  },
  computed: {
    ...mapState({
      log_id: (state) => state.conversation.log_id,
    }),
  },
  methods: {
    // 点击发送
    sendClick() {
      //this.$trtcCalling.getCameras()
      // this.$trtcCalling.call({
      //     userID: 'police_1',
      //     type: 1,
      //     timeout: 30,
      //   });
      //   return ;
      if (this.message == "" || this.message.trim().length === 0) {
        this.message = "";
        this.$message.error("不能发送空消息哦!");
        return;
      }
      this.sendText();
    },
    //发送消息
    sendText() {
      sendMsg({
        type: "text",
        content: this.message,
        log_id: this.log_id,
      }).then((res) => {
        if (res.code == 1) {
        }else{
          this.$message.error(res.msg)
          return;
        }
        this.message = "";
          setTimeout(() => {
            this.$emit("toBottom");
          }, 300);
      });
    },
  },
  mounted() {
    this.$bus.$on("getImData", (item) => {
    });
    this.$bus.$on("clearImData", () => {
      this.$store.commit("deleteMessage");
    });
    
  },
  destroyed() {
    this.$bus.$off("getImData");
    this.$bus.$off("clearImData");
    
  },
};
</script>

<style lang="scss" scoped>
.chat_footer {
  width: 100%;
  height: 50px;
  display: flex;
  .input_div {
    display: flex;
    align-items: center;

    width: 80%;
    input {
      margin-left: 5%;
      width: 100%;
      height: 30px;
      background: #f9f9f9;
      border-radius: 5px;
      border: none;
      color: #707070;
      &:focus {
        outline: none;
      }
    }
  }
  .send_out {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    .btn {
      width: 52px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      background: #f4725d;
      border-radius: 5px;
      font-weight: 500;
      font-size: 14px;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
</style>