import { get, post } from '@/api/http/http'
//报警记录查询
export function sendMsg(params) {
    return post('Communication/sendmsg', params)
}
//等待报警队列
export function getQueue(params) {
    return post('Communication/queue', params)
}
//等待报警队列
export function getAqueue(params) {
    return post('Communication/aqueue', params)
}
//结束通话
export function stopQueue(params) {
    return post('Communication/stopqueue', params)
}