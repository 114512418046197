<template>
  <div class="left_menu">
    <div class="menu_list">
      <!-- LOGO -->
      <div class="logo">
        <img src="@/assets/images/home/logo.png" alt="" v-show="!isCollaspe" />
        <img
          src="@/assets/small_logo.png"
          alt=""
          class="small_logo"
          v-show="isCollaspe"
        />
      </div>
      <!-- 左侧导航栏 -->
      <el-menu
        :default-active="this.currPath"
        :collapse="isCollaspe"
        text-color="#ffffff"
        background-color="#282d3a"
        active-text-color="#ffffff"
        router
      >
        <el-menu-item
          v-for="item in menuData"
          :key="item.name"
          :index="item.path"
          @select="itemClick(item.path)"
        >
          <i :class="item.icon"></i>
          &nbsp;&nbsp;
          <span class="title">
            {{ item.name }}
            <div class="badge" v-if="item.num">
              {{ item.num > 99 ? " 99+" : item.num }}
            </div>
          </span>
        </el-menu-item>
      </el-menu>
    </div>
    <!-- 个人登录账号密码信息 -->
    <div class="author">
      <div class="avator">
        <img src="@/assets/images/falseData/pilice.png" alt="" />
      </div>
      <div class="dropDown">
        <el-dropdown
          placement="bottom"
          trigger="click"
          @command="handleCommand"
        >
          <span class="el-dropdown-link">
            {{ isCollaspe ? "" : name
            }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="1">修改密码</el-dropdown-item>
            <el-dropdown-item command="2">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- <div class='to_login' @click="toLogin">登录</div> -->
      </div>
    </div>
    <!-- 修改密码弹窗 -->
    <el-dialog
      title="修改密码"
      :visible.sync="isShowUpdatePass"
      width="30%"
      top="35vh"
      @close="handleClose"
    >
      <div class="body">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
          :hide-required-asterisk="true"
        >
          <el-form-item label="原密码:" prop="beforePass">
            <el-input v-model="ruleForm.beforePass"></el-input>
          </el-form-item>
          <el-form-item label="新密码:" prop="afterPass">
            <el-input v-model="ruleForm.afterPass"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShowUpdatePass = false">取 消</el-button>
        <el-button type="primary" @click="resetPassW">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { resetPass, logout } from "@/api/login/login";
export default {
  props: {
    menuData: {
      type: Array,
      default() {
        return [];
      },
    },
    isCollaspe: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currPath: "/index",
      name: "李山彪",
      isShowUpdatePass: false, //修改密码弹出框
      //校验表单
      ruleForm: {
        beforePass: "", //原密码
        afterPass: "", //新密码
      },
      //校验规则
      rules: {
        beforePass: [
          { required: true, message: "请输入原始密码", trigger: "blur" },
        ],
        afterPass: [
          { required: true, message: "请输入新密码", trigger: "blur" },
        ],
      },
      //判断是否登录
      token: "",
    };
  },
  watch: {
    $route(v, h) {
      //console.log(v,h);
      this.currPath = v.path;
    },
  },
  methods: {
    //左侧导航栏选择
    itemClick(path) {
      if (path === "/home" && sessionStorage.getItem("isCall") === "1") {
        return;
      }
      this.currPath = path;
    },
    //下拉列表选择
    handleCommand(command) {
      if (command == 1) {
        //点击修改密码
        this.isShowUpdatePass = true;
      } else if (command == 2) {
        //点击退出登录
        this.logout();
      }
    },
    //退出登录
    logout() {
      this.$confirm("此操作将会退出登录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (sessionStorage.getItem("isCall") === "1") {
            return this.$message.error("请先关闭通话再进行退出登录");
          }
          logout().then((res) => {
            this.$store.commit("clearAccount");
            this.$store.dispatch("logout");
            this.$trtcCalling.logout();
            this.$message.success(res.msg);
            this.$router.push("/login");
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消退出",
          });
        });
    },
    //关闭修改密码弹窗回调,重置表单验证
    handleClose() {
      this.$refs.ruleForm.resetFields();
    },
    //登录
    toLogin() {},
    resetPassW() {
      if (sessionStorage.getItem("isCall") === "1") {
        return this.$message.error("请先关闭通话再进行修改");
      }
      resetPass({
        oldpass: this.ruleForm.beforePass,
        password: this.ruleForm.afterPass,
      }).then((res) => {
        if (res.code == 1) {
          this.$message.success("修改成功，请重新登录");
          this.$store.commit("clearAccountData");
          this.$router.push("/login");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
  created() {
    //console.log(this.$store.state);
    this.name = this.$store.state.account.name;
    this.currPath = this.$route.path;
  },
};
</script>
<style lang="scss" scoped>
.left_menu {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .author {
    height: 260px;
    display: flex;
    flex-direction: column;
    .avator {
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        object-fit: cover;
        background: #fff;
      }
    }
    .dropDown {
      display: flex;
      justify-content: center;
      cursor: pointer;
      .el-dropdown {
        width: 104px;
        height: 36px;
        border: 1px solid #ffffff;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
      }
      .to_login {
        width: 104px;
        height: 36px;
        border: 1px solid #ffffff;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
      }
    }
  }
}
.menu_list {
  width: 100%;
  font-size: 12px;
  .logo {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    .small_logo {
      width: 40px;
      height: 40px;
    }
  }
  .el-menu {
    border: none;
    .title {
      position: relative;
      .badge {
        position: absolute;
        top: 0;
        left: 75px;
        width: 24px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        border-radius: 50%;
        background: red;
        font-size: 10px;
        transform: scale(0.8);
      }
    }
  }
  .el-submenu__icon-arrow {
    font-size: 24px;
  }
}
.is-active {
  background: #f4725d !important;
}
.iconfont {
  color: #fff;
}
</style>