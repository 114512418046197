<template>
  <div class="pagination_div">
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="paginationData.page"
      :page-sizes="[10, 15, 20, paginationData.count]"
      :page-size="paginationData.limit"
      layout="total, sizes, prev, pager, next, jumper"
      :total="paginationData.count"
      background
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    paginationData: {
      type: Object,
      default() {
        return {
          count: 0,
          limit: 10,
          page: 1,
        };
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    //limit改变
    handleSizeChange(val) {
      this.$emit("limitChange", val);
    },
    //page改变
    handleCurrentChange(val) {
      this.$emit("pageChange", val);
      this.currPage = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination_div {
  width: 100%;
  background: #fff;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
  padding: 15px 0 30px 0;
  .el-pagination {
    margin-right: 15px;
  }
}
</style>