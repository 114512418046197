import { get, post } from '@/api/http/http'

//登录
export function getLogin(params) {
    return post('Index/accountLogin', params)
}
//登录
export function tokenToInfo(params) {
    return post('Base/getAccount', params)
}
//更新用户密码
export function resetPass(params) {
    return post('Station/revise_pass', params)
}
//更新用户密码
export function logout(params) {
    return post('/Index/logout', params)
}


