<template>
  <div class="setting_card" v-if="this.$route.path == '/home'">
    <div
      class="setting_item"
      @click="openCameras"
      :class="{ isActive: isOpenCamera }"
    >
      <div class="setting_icon">
        <img src="@/assets/images/home/header/police_camera.png" alt="" />
      </div>
      <div class="setting_name">警员摄像头</div>
    </div>
    <div
      class="setting_item"
      v-for="(item, index) in settingList"
      :key="index"
      @click="settingClick(index, item.type)"
      :class="{ isActive: index === curr }"
    >
      <div class="setting_icon">
        <img :src="item.images" alt="" />
      </div>
      <div class="setting_name">
        {{ item.name }}
      </div>
    </div>
    <el-dialog title="坐席转接" :visible.sync="isShowNext" width="700px">
      <div class="next_body">
        <div class="top">
          <div class="title">地区:</div>
          <div class="forms">
            <el-cascader
              v-model="selectCity"
              :options="city"
              @change="handleChange"
              ref="cascaderRef"
            ></el-cascader>
          </div>
        </div>
        <div class="bottom">
          <div class="title">坐席：</div>
          <div class="forms">
            <el-select v-model="next" placeholder="请选择">
              <el-option
                v-for="item in nextList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShowNext = false">取 消</el-button>
        <el-button type="primary" @click="transferClick">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ScreenShort from "js-web-screen-shot";
import {
  uploadFile, //上传文件到OSS
  getStation, //接警员列表
  getMyselectCity, //获取我的地址
  getSuretransfer, //确认转接
  setSmartrplog,
} from "@/api/alarmRecord/alarmRecord.js";
import { getCity } from "@/api/menu/menu.js";
import { mapState } from "vuex";
export default {
  data() {
    return {
      settingList: [
        // {
        //   images: require("@/assets/images/home/header/audio_setting.png"),
        //   name: "音频设置",
        // },
        // {
        //   images: require("@/assets/images/home/header/picture_setting.png"),
        //   name: "图像设置",
        // },
        {
          images: require("@/assets/images/home/header/cut_setting.png"),
          name: "立即截图",
          type: 2,
        },
        // {
        //   images: require("@/assets/images/home/header/next_setting.png"),
        //   name: "坐席转接",
        //   type: 3,
        // },
      ],
      curr: -1,
      isShowNext: false,
      city: [], //地区列表
      selectCity: [], //选择的地址
      next: "", //选择的接警员id
      nextList: [], //接警员列表
      selection: "", //选择的地址的区域
    };
  },
  computed: {
    ...mapState({
      log_id: (state) => state.conversation.log_id,
      isOpenCamera: (state) => state.conversation.setCamera,
    }),
  },
  methods: {
    settingClick(index, type) {
      this.curr = index;
      const callback = (base64) => {
        if (this.log_id == "") {
          return this.$message.error("请先连接通话，才可保存截图");
        }
        uploadFile({
          file: base64,
          oss: 0,
        }).then((res) => {
          setSmartrplog({
            id: this.log_id,
            quickimg: res.data.url,
          }).then((res) => {
            console.log(res);
            this.curr = -1;
          });
        });
      };
      const closeFn = () => {
        this.curr = -1;
      };
      if (type === 2) {
        console.log(type);
        const ta = new ScreenShort({
          enableWebRtc: true,
          completeCallback: callback,
          closeCallback: closeFn,
        });
      } else if (type === 3) {
        this.isShowNext = true;
      }
    },
    handleChange(e, from, thsAreaCode) {
      thsAreaCode = this.$refs["cascaderRef"].getCheckedNodes()[0].pathLabels;
      this.selection = thsAreaCode[2];
      this.changeStation();
      console.log(thsAreaCode[2]);
    },
    getCity() {
      getCity().then((res) => {
        this.city = res.data;
      });
    },
    getStation(data) {
      getStation(data).then((res) => {
        if (res.code == 1) {
          console.log(res.data.list);
          this.nextList = res.data.list;
          this.next = "";
        }
      });
    },
    changeStation() {
      getMyselectCity({ search: this.selection }).then((res) => {
        if (res.code == 1) {
          if (res.data.list.length) {
            this.getStation({ city_id: res.data.list[0].id });
          } else {
            this.$message.error("该地区没有接警员!");
          }
        }
      });
    },
    transferClick() {
      if (this.log_id == "") {
        this.isShowNext = false;
        return this.$message.error("请先接通电话,才可进行转接操作");
      }
      getSuretransfer({
        station_id: this.next,
        log_id: this.log_id,
      }).then((res) => {
        if (res.code == 1) {
          this.$message.success("转接成功!");
          this.$trtcCalling.hangup();
        } else {
          this.$message.error(res.msg);
        }
      });
      this.isShowNext = false;
    },
    openCameras() {
      if (this.log_id == "") {
        return this.$message.error("请先连接通话，才可以开启摄像头");
      }
      console.log(this.isOpenCamera);
      this.$store.commit('setCamera',!this.isOpenCamera)
      if (this.isOpenCamera) {
        this.$trtcCalling.openCamera();
        localStorage.removeItem('openCamera')
        this.$store.commit('setShowCloseMyCamera',false)
      } else {
        this.$trtcCalling.closeCamera();
        localStorage.setItem('openCamera','0')
        this.$store.commit('setShowCloseMyCamera',true)
      }
    },
  },
  created() {
    this.getCity();
    this.getStation();
  },
};
</script>

<style  lang="scss" scoped>
.setting_card {
  display: flex;
  width: 60%;
  height: 100%;
  .setting_item {
    width: 20%;
    min-width: 60px;
    display: flex;
    flex-direction: column;
    height: 100%;
    cursor: pointer;
    div {
      flex: 1;
    }
    .setting_icon {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      img {
        width: 20px;
        height: 20px;
      }
    }
    .setting_name {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      font-weight: 500;
      color: #ffffff;
    }
  }
  .next_body {
    height: 300px;
    display: flex;
    .top {
      flex: 1;
      display: flex;
      .title {
        min-width: 60px;
        padding-top: 6px;
        display: flex;
        font-size: 18px;
        font-weight: 500;
        color: #707070;
      }
      .forms {
        width: 100%;
      }
    }
    .bottom {
      flex: 1;
      display: flex;
      .title {
        min-width: 60px;
        padding-top: 6px;
        display: flex;
        font-size: 18px;
        font-weight: 500;
        color: #707070;
      }
      .forms {
        width: 100%;
      }
    }
  }
}
.isActive {
  background: #f4725d;
}
</style>