
<template>
  <!-- 这里是整个页面最外面的布局 -->
  <div class="main-home-temp">
    <el-container>
      <el-aside
        :width="isCollaspe ? '60px' : '182px'"
        class="hidden-sm-and-down"
      >
        <menu-list :menuData="menuList" :isCollaspe="isCollaspe" />
      </el-aside>
      <el-container>
        <el-header>
          <main-header
            :isCollaspe="isCollaspe"
            @collaspeChange="collaspeChange"
          />
        </el-header>
        <el-main>
          <transition name="fade-transform" mode="out-in">
            <router-view></router-view>
          </transition>
        </el-main>
      </el-container>
    </el-container>
    <el-drawer
      title="我是标题"
      :visible.sync="drawer"
      direction="ltr"
      :withHeader="false"
      size="152px"
    >
      <div class="drawer_class">
        <menu-list :menuData="menuList" />
      </div>
    </el-drawer>
  </div>
</template>
<script>
import menuList from "@/components/mainHomeTemp/menuList/menuList.vue";
import MainHeader from "@/components/mainHomeTemp/mainHeader/mainHeader.vue";
import { getMenuData } from "@/api/menu/menu";
import { getObtain } from "@/api/alarmRecord/alarmRecord";
import { Loading } from 'element-ui';
export default {
  data() {
    return {
      menuList: [],
      accountInfo: {}, //当前会话的用户信息
      isCollaspe: false, //是否折叠
      drawer: false, //是否打开抽屉折叠
      isShowDrawer: false,
      timer: "",
    };
  },
  components: {
    menuList,
    MainHeader,
  },
  watch: {
    $route(v, h) {
      if (this.drawer === true) {
        this.drawer = false;
      }
      this.getObtains({
        field: "type",
        status: {
          0: "in",
          1: 0,
        },
      });
    },
  },
  methods: {
    getMenuData() {
      getMenuData().then(
        (res) => {
          this.menuList = res.data.menu;
          this.getObtains({
            field: "type",
            status: {
              0: "in",
              1: 0,
            },
          });
        },
        (j) => {
          console.log(j);
        }
      );
    },
    collaspeChange() {
      if (document.body.clientWidth <= 992) {
        this.isShowDrawer = true;
      } else {
        this.isShowDrawer = false;
      }
      if (this.isShowDrawer) {
        return (this.drawer = true);
      }
      this.isCollaspe = !this.isCollaspe;
    },
    getObtains(obj) {
      this.menuList[2].num = 0;
      this.menuList[3].num = 0;
      this.menuList[4].num = 0;
      getObtain(obj).then((res) => {
        res.data.forEach((item) => {
          if (+item.type === 0 || +item.type === 1) {
            this.$set(
              this.menuList[2],
              "num",
              this.menuList[2].num + item.count
            );
          } else if (+item.type === 2) {
            this.$set(this.menuList[3], "num", item.count);
          } else if (+item.type === 3) {
            this.$set(this.menuList[4], "num", item.count);
          }
        });
      });
    },
    //notify
    getInfo(title, icon, path) {
      setTimeout(() => {
        console.log('打开notify');
        this.$notify({
          title: "",
          dangerouslyUseHTMLString: true,
          position: 'bottom-right',
          message: this.$createElement("div", { class: "notify" }, [
            this.$createElement(
              "div",
              { class: "header" },
              `你有${title}需处理`
            ),
            this.$createElement("div", { class: "body" }, [
              this.$createElement(
                "img",
                {
                  domProps: {
                    src: `${icon}`,
                  },
                },
                null
              ),
            ]),
            this.$createElement("div", { class: "footer" }, [
              this.$createElement(
                "div",
                {
                  class: "confrim",
                  on: {
                    click: this.toMain(path),
                  },
                },
                "查看"
              )
            ]),
          ]),
          duration: 59000,
        });
      });
    },
    toMain(path) {
      return () => {
        this.$notify.closeAll();
        if (this.$route.path==path) {
          this.$bus.$emit('searchStatus',{
            path,
            status:0
          })
          let loadingInstance = Loading.service();
          setTimeout(()=>{
             loadingInstance.close();
          },1500)
        }else{
          this.$router.push(path);
        }
        
      };
    },
    cancel() {
      this.$notify.close();
    },
  },
  created() {
    //获取左侧菜单栏数据
    this.getMenuData();

    //在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem(
        "account",
        JSON.stringify(this.$store.state.account)
      );
    });
    //获取个人信息
    if (sessionStorage.getItem("account") && sessionStorage.getItem("account")!=='{}') {
      console.log('mainHomeTemp');
      console.log(JSON.parse(sessionStorage.getItem("account")))
      this.$store.commit(
        "getAccount",
        JSON.parse(sessionStorage.getItem("account"))
      );
      this.$store.commit("setTimUser", this.$store.state.account.timUser);
      //this.$store.dispatch("timLogin");
      this.$trtcCalling.login(this.$store.state.account.timUser);
      sessionStorage.removeItem("account");
    }
  },
  mounted() {
    window.addEventListener("resize", () => {
      // 变化后需要做的事
      if (document.body.clientWidth > 992) {
        this.drawer = false;
      }
      if (
        !this.isCollaspe &&
        document.getElementsByClassName("el-main")[0].clientWidth <= 1150
      ) {
        this.isCollaspe = true;
      }
      if (
        this.isCollaspe &&
        document.getElementsByClassName("el-main")[0].clientWidth > 1150
      ) {
        this.isCollaspe = false;
      }
    });
    this.$bus.$on("setstatusChange", () => {
      this.getObtains({
        field: "type",
        status: {
          0: "in",
          1: 0,
        },
      });
    });
    this.timer = setInterval(() => {
      this.$notify.closeAll();
      this.menuList.forEach((item) => {
        if (item.num > 0 && !this.$store.state.conversation.log_id) {
          if (item.id === 1) {
            this.getInfo('待处理音视频报警',require("@/assets/images/icon/video.png"),"/alarmRecord");
          } else if (item.id === 2) {
            this.getInfo("待处理留言报警",require('@/assets/images/icon/edit.png'),"/messageAlarm");
          } else if (item.id === 3) {
            this.getInfo("待处理静默报警",require("@/assets/images/icon/silent.png"),"/silentAlarm");
          }
        }
      });
    }, 60000);
  },
  destroyed() {
    this.$bus.$off("setstatusChange");
    clearInterval(this.timer);
  },
};
</script>
<style lang="scss" scoped>
.main-home-temp {
  width: 100%;
  height: 100%;
  opacity: 1;
  background: #f0f2f5;
  .el-container {
    width: 100%;
    height: 100%;
    border: none;
    .el-aside {
      height: 100%;
      opacity: 1;
      background: #282d3a;
      transition: 0.5s;
      .logo_style {
        padding-top: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .logo {
        width: 84px;
        height: 84px;
      }
      .collaspeLogo {
        width: 36px;
        height: 36px;
      }
      .logo_name {
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-weight: 800;
        color: #ffffff;
        padding-bottom: 14px;
      }
    }
    .el-header {
      width: 100%;
      height: 60px !important;
      opacity: 1;
      background: #282d3a;
      padding: none;
    }
    .el-main {
      padding: 0;
      width: 100%;
      background: #f4f4f4;
    }
  }
}
.logo_style {
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #1c2233;
}
.logo {
  width: 84px;
  height: 84px;
}
.logo_name {
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 800;
  color: #ffffff;
  padding-bottom: 14px;
}
.drawer_class {
  background: #1c2233 !important;
  height: 100%;
}
.box_shadow {
  box-shadow: 0px 10px 15px 0px rgb(0 0 0 / 16%);
}
</style>
