import { get, post } from '@/api/http/http'
//报警记录查询
export function getTable(params) {
    return post('receive_police/get_smartrplog', params)
}
//警情分类
export function getCaseType(params) {
    return post('Base/casetype', params)
}
//路段标注
export function getRoad(params) {
    return post('Base/road', params)
}
//场所列表
export function getPlaceType(params) {
    return post('Base/placetype', params)
}
//批量操作报警记录
export function setAllSmartrplog(params) {
    return post('receive_police/set_all_smartrplog', params)
}
//设置更新报警记录
export function setSmartrplog(params) {
    return post('receive_police/set_smartrplog', params)
}
//文件资源上传接口
export function uploadFile(params) {
    return post('Base/upload', params)
}
//接警员列表
export function getStation(params) {
    return post('Station/get_station', params)
}
//单位列表/城市划分
export function getMyselectCity(params) {
    return post('Base/smartrpcity', params)
}
//音视频转接请求
export function getSuretransfer(params) {
    return post('Communication/transfer', params)
}
//确认转接
export function getsuretransfer(params) {
    return post('Communication/suretransfer', params)
}
//拒绝转接
export function canceltransfer(params) {
    return post('Communication/canceltransfer', params)
}
//数据分析
export function getObtain(params) {
    return post('Analyze/obtain', params)
}
//修改用户禁言时间
export function setNoSayTime(params) {
    return post('/receive_police/set_bantime', params)
}