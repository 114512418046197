<template>
  <div class="login">
    <el-row>
      <el-col
        :xs="{ span: 24, offset: 0 }"
        :sm="{ span: 24, offset: 0 }"
        :md="{ span: 24, offset: 0 }"
        :lg="{ span: 24, offset: 0 }"
        :xl="{ span: 24, offset: 0 }"
      >
        <div class="login_img">
          <img
            src="@/assets/images/login/login_logo.png"
            alt=""
            class="bg_img"
          />
          <div class="login_box">
            <div class="logo">
              <img src="@/assets/images/login/logo.png" alt="" />
            </div>
            <div class="username_div">
              <div>
                <el-form
                  :model="userForm"
                  :rules="rules"
                  ref="userForm"
                  label-width="100px"
                  :hide-required-asterisk="true"
                >
                  <el-form-item prop="username">
                    <div class="label">账号</div>
                    <el-input
                      v-model="userForm.username"
                      placeholder="请输入您的账号"
                    ></el-input>
                  </el-form-item>
                  <el-form-item prop="password">
                    <div class="label">密码</div>
                    <el-input
                      type="password"
                      v-model="userForm.password"
                      placeholder="请输入您的密码"
                      @keyup.enter="login"
                    ></el-input>
                  </el-form-item>
                </el-form>
              </div>
            </div>
            <div class="login_btn">
              <div class="btn" @click="login">登录</div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import {
  getLogin, //登录
  tokenToInfo, //令牌转换数据
} from "@/api/login/login";
import axios from "axios";
export default {
  data() {
    return {
      //校验表单
      userForm: {
        username: "",
        password: "",
      },
      //校验规则
      rules: {
        username: [
          { required: true, message: "请输入账号", trigger: "blur" },
          {
            min: 6,
            max: 12,
            message: "长度在 6 到 12 个字符",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 6,
            max: 12,
            message: "长度在 6 到 12 个字符",
            trigger: "blur",
          },
        ],
      },
      //判断是否登录状态
      isLogin: false,
      userID: 0,
      userSig: "",
    };
  },
  methods: {
    //登录账号密码
    login() {
      console.log(123);
      if (!window.navigator.onLine) {
        return this.$message.error("网络出现错误,请检查你的网络是否正常!");
      }
      let parmas = {
        username: this.userForm.username,
        password: this.userForm.password,
      };
      //登录
      getLogin(parmas).then((res) => {
        //console.log(res);
        //成功登录状态码
        if (res.code == "1") {
          sessionStorage.setItem("token", res.data.token);
          localStorage.setItem('loginForm',JSON.stringify(this.userForm))
          this.userSig = res.data.imtoken;
          this.$store.commit("getToken", res.data.token);
          //首次登录设置token
          axios.defaults.headers.get["token"] = res.data.token;
          axios.defaults.headers.post["token"] = res.data.token;
          this.tokenToInfo();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    tokenToInfo() {
      tokenToInfo().then(
        (res) => {
          //console.log(res.data);
          // 登录IM
          const timUser = {
            userID: "police_" + res.data.id,
            userSig: this.userSig,
          };
          this.$set(res.data, "timUser", timUser);
          console.log('Home');
          this.$store.commit("getAccount", res.data);
          this.$store.commit("setTimUser", timUser);
          //this.$store.dispatch("timLogin");
          // 登录 trtcCalling
          this.$trtcCalling.login(timUser);
          this.$router.push("/index");
        },
        (rej) => {}
      );
    },
  },
  created() {
    // this.$store.state.account.name
    if (localStorage.getItem('loginForm')) {
      this.userForm=JSON.parse(localStorage.getItem('loginForm'))
    }
    this.$notify.closeAll();
  },
  beforeCreate() {},
};
</script>
<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100%;
  font-size: 14px;
  .el-row,
  .el-col {
    height: 100%;
  }
  .login_img {
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    background-image: url(../../assets/images/login/login_bg.png);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .bg_img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 3;
      width: 1300px;
      height: 700px;
    }
    .login_box {
      z-index: 999;
      width: 456px;
      height: 630px;
      background: #ffffff;
      border-radius: 5px;
      box-shadow: 0px 4px 60px 0px rgba(244, 114, 93, 0.1);
      .logo {
        height: 180px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 200px;
          height: 68px;
        }
      }
      .username_div {
        padding: 0 80px;
        font-size: 18px;
        font-weight: 500;
        color: #737373;
        .label {
          font-size: 15px;
          font-weight: 500;
          color: #737373;
        }
      }
      .login_btn {
        height: 180px;
        display: flex;
        justify-content: center;
        align-items: center;
        .btn {
          width: 264px;
          height: 52px;
          line-height: 52px;
          text-align: center;
          background: #f4725d;
          border-radius: 50px;
          font-size: 18px;
          font-weight: 500;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
  }
}
</style>