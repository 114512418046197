import tim from './tim'
import TRTCCalling from 'trtc-calling-js';
import {imsdk} from '@/config/index'
let options = {
    SDKAppID: imsdk, // 接入时需要将0替换为您的即时通信IM应用的 SDKAppID
    // 从v0.10.2起，新增 tim 参数
    // tim 参数适用于业务中已存在 TIM 实例，为保证 TIM 实例唯一性
    tim: tim
  };
  export function createTrtcCalling() {
    return new TRTCCalling(options);
  }
  