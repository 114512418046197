import Vue from 'vue'
import Vuex from 'vuex'

import conversation from './modules/conversation'
import tim from '@/util/tim'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',//token
    account: {},//account
  },
  mutations: {
    //存储token
    getToken(state, token) {
      console.log(123);
      state.token = token;
      console.log(state.token);
    },
    //存储account
    getAccount(state, account) {
      console.log(456);
      state.account = account;
      console.log(state.account);
    },
    //清除个人信息
    clearAccount(state) {
      state.account = {};
      console.log(state.accountData);
    },
    // 保存tim账号
    setTimUser(state, timUser) {
      state.timUser = timUser
      console.log(state.timUser, '---timUser---')
    },
    // 修改tim准备状态
    setTimReady(state, data) {
      state.isTimReady = data;
      console.log(state.isTimReady, '---isTimReady---')
    },
  },
  actions: {
    // 登录IM
    timLogin({
      state,
      dispatch
    }) {
      tim.login({
        userID: state.timUser.userID,
        userSig: state.timUser.userSig
      }).then(res => {
        if (state.isTimReady) {
          console.log('---Tim登录成功: ---', res)
          // dispatch('getGourpList');
        }
      }).catch(imError => {
        console.error(imError, '登录失败')
        if (imError.code === 2000) {
          console.error(imError.message + ', 请检查是否正确填写了 SDKAPPID')
        } 
      })
    },
    // 退出IM登陆
    logout({
      state,
      dispatch
    }) {
      let promise = tim.logout()
      console.log('登出准备')
      promise.then(function (imResponse) {
        console.log(imResponse.data, '---登出成功---'); // 登出成功
        sessionStorage.clear();
      }).catch(function (imError) {
        console.warn('logout error:', imError);
      });
    },
  },
  modules: {
    conversation,
  }
})
