<template>
  <div class="help_person_video" id='videoDom'>
    <img
      :src="img_Url + '/uploads/20211118/51f16a7e72debbce59af1dbffbb12c0d.gif'"
      alt=""
      v-if="type == 1"
    />
    <img
      src="@/assets/images/home/noData/no_connect_person.png"
      alt=""
      v-if="type == ''"
      style="width:400px;height:300px"
    />
  </div>
</template>

<script>
import { qiniu_url } from "@/config/index";
export default {
  data() {
    return {
      from_account: "",
      type: "",
    };
  },
  computed: {
    img_Url() {
      return qiniu_url;
    },
  },
  methods: {
    getVideo() {
      let myType;
      if (+this.type === 0) {
        myType = 2;
      } else if (+this.type === 1) {
        myType = 1;
      }
      if (myType===1) {
        this.$trtcCalling.call({
          userID: this.from_account,
          type: myType,
          timeout: 30,
        });
      }else if(myType===2){
        this.$trtcCalling.call({
          userID: this.from_account,
          type: myType,
          timeout: 30,
        }).then(res=>{
          console.log('连接成功!');
        });
      }
    },
  },
  mounted() {
    this.$bus.$on("getVideoData", (item) => {
      this.from_account = item.from_account;
      this.type = item.log.type;
      console.log(this.type);
      this.getVideo();
    });
    this.$bus.$on("clearVideoData", () => {
      this.from_account = "";
      this.type = "";
    });
    this.$bus.$on("openDevice", () => {
      if (+this.type === 0) {
        console.log({userID:this.from_account, videoViewDomID:'videoDom'},'打开敌方视频');
        this.$trtcCalling.startRemoteView({userID:this.from_account, videoViewDomID:'videoDom'})
        console.log('一开');
      }
    });
  },
  destroyed() {
    this.$bus.$off("getVideoData");
    this.$bus.$off("clearVideoData");
    this.$bus.$off("openDevice");
  },
};
</script>

<style lang="scss" scoped>
.help_person_video {
  margin-top: 2vh;
  height: 64vh;
  border-radius: 5px;
  background: #282d3a;
  box-shadow: 0px 4px 30px 0px rgba(244, 114, 93, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    height: 100%;
  }
  
}
</style>