<template>
  <div class="missed_calls">
    <aralmTable :alarmList="alarmList" />
    <pagination
      :paginationData="paginationData"
      @limitChange="limitChange"
      @pageChange="pageChange"
      v-show="paginationData.count >= 10"
    />
  </div>
</template>

<script>
import aralmTable from "@/components/missedCalls/aralmTable/aralmTable.vue";
import Pagination from "@/components/pagination/pagination.vue";

import { getQueue } from "@/api/home/home";
export default {
  components: {
    aralmTable,
    Pagination,
  },
  data() {
    return {
      alarmList: [],
      paginationData: {
        count: 0,
        limit: 10,
        page: 1,
      },
    };
  },
  methods: {
    limitChange(val) {
      this.paginationData.limit = val;
      this.getQueue()
    },
    pageChange(val) {
      this.paginationData.page = val;
      this.getQueue()
    },
    getQueue() {
      getQueue({
        status:2,
        limit: this.paginationData.limit,
        page: this.paginationData.page,
        order:'createtime desc',
        is_over:1,
      }).then((res) => {
          this.paginationData.count=res.data.count;
          this.alarmList=res.data.list
          console.log(res);
      });
    },
  },
  created() {
      this.getQueue();
  },
  mounted() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.missed_calls {
  padding: 0 15px;
}
</style>