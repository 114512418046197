<template>
  <div class="message_alarm">
    <functionalArea
      :caseTypeList="caseTypeList"
      :placeTypeList="placeTypeList"
      :roadTypeList="roadTypeList"
      @statusChange="statusChange"
      @searchAll="searchAll"
      ref="funcAreaRef"
    />
    <aralmTable
      :alarmList="alarmList"
      @setAllPlog="setAllSmartrplog"
      @editAlarmItem="editAlarmItem"
    />
    <pagination
      :paginationData="paginationData"
      @limitChange="limitChange"
      @pageChange="pageChange"
      v-show="paginationData.count>=10"
    />
  </div>
</template>

<script>
import functionalArea from "@/components/messageAlarm/functionalArea/functionalArea.vue";
import aralmTable from "@/components/messageAlarm/aralmTable/aralmTable.vue";
import Pagination from "@/components/pagination/pagination.vue";

import {
  getTable, //获取表格列表
  getCaseType, //获取警情分类
  getPlaceType, //获取场所列表
  getRoad, //获取路段标注
  setAllSmartrplog, //批量操作报警记录
  setSmartrplog, //设置更新报警记录
} from "@/api/alarmRecord/alarmRecord";
export default {
  components: {
    functionalArea,
    aralmTable,
    Pagination,
  },
  data() {
    return {
      alarmList: [], //表格
      caseTypeList: [], //警情分类列表
      placeTypeList: [], //场所列表
      roadTypeList: [], //路段标注
      paginationData: {
        count: 0,
        limit: 10,
        page: 1,
      },
      searchObj: {
        search: '', //搜索内容
        casetype_id: '', //警情类型
        placetype_id: '', //场所类型
        road_id: '', //路段注明
        name: '', //报警员称谓
        mobile: '', //电话号码
        time: '',
      },
    };
  },
  methods: {
    limitChange(val) {
      this.paginationData.limit = val;
      this.getTable(
        this.$refs.funcAreaRef.eventThink[this.$refs.funcAreaRef.curr].status,
        this.searchObj.search,
        this.searchObj.casetype_id,
        this.searchObj.placetype_id,
        this.searchObj.road_id,
        this.searchObj.name,
        this.searchObj.mobile,
        this.searchObj.time
      );
    },
    pageChange(val) {
      this.paginationData.page = val;
      this.getTable(
        this.$refs.funcAreaRef.eventThink[this.$refs.funcAreaRef.curr].status,
        this.searchObj.search,
        this.searchObj.casetype_id,
        this.searchObj.placetype_id,
        this.searchObj.road_id,
        this.searchObj.name,
        this.searchObj.mobile,
        this.searchObj.time
      );
    },
    //设置更新报警记录
    editAlarmItem(editForm) {
      setSmartrplog(editForm).then((res) => {
        this.$message.success(res.msg);
        this.getTable(
          this.$refs.funcAreaRef.eventThink[this.$refs.funcAreaRef.curr].status
        );
      });
    },
    //检索按钮
    searchAll(searchObj) {
      this.paginationData.limit = 10;
      this.paginationData.page = 1;
      this.searchObj = searchObj;
      this.getTable(
        searchObj.status,
        searchObj.search,
        searchObj.casetype_id,
        searchObj.placetype_id,
        searchObj.road_id,
        searchObj.name,
        searchObj.mobile,
        searchObj.time
      );
    },
    //状态改变
    statusChange(status) {
      this.paginationData.limit = 10;
      this.paginationData.page = 1;
      this.getTable(status);
    },
    //获取表格列表
    getTable(
      status,
      search,
      casetype_id,
      placetype_id,
      road_id,
      name,
      mobile,
      createtime
    ) {
      let names, mobiles;
      if (name) {
        console.log(123);
        names = {
          0: "like",
          1: name + "",
        };
      }
      if (mobile) {
        mobiles = {
          0: "like",
          1: mobile + "",
        };
      }
      getTable({
        status: {
          0: "in",
          1: status + "",
        },
        search,
        casetype_id,
        placetype_id,
        road_id,
        name: names,
        mobile: mobiles,
        createtime,
        type: 2,
        limit: this.paginationData.limit,
        page: this.paginationData.page
      }).then((res) => {
        //console.log(res.data.list);
        this.paginationData.count = res.data.count;
        this.alarmList = res.data.list;
      });
    },
    //获取警情分类
    getCaseType() {
      getCaseType().then((res) => {
        //console.log(res.data.list);
        this.caseTypeList = res.data.list;
      });
    },
    //获取场所列表
    getPlaceType() {
      getPlaceType().then((res) => {
        this.placeTypeList = res.data.list;
        console.log(res.data.list);
      });
    },
    //获取路段标注
    getRoad() {
      getRoad({
        city_id: this.$store.state.account.city_id,
      }).then((res) => {
        this.roadTypeList = res.data.list;
        console.log(res);
      });
    },
    setAllSmartrplog(ids, filed, set) {
      console.log(ids, filed, set);
      setAllSmartrplog({
        ids,
        filed,
        set,
      }).then((res) => {
        this.getTable(
          this.$refs.funcAreaRef.eventThink[this.$refs.funcAreaRef.curr].status
        );
        this.$refs.funcAreaRef.getObtains({
          field: "status",
          type: {
            0: "in",
            1: "2",
          }
        });
        this.$bus.$emit('setstatusChange')
        this.$message.success("操作成功!");
      });
    },
  },
  created() {
    if (this.$route.query.log_id) {
      getTable({
        id: this.$route.query.log_id,
      }).then((res) => {
        this.alarmList = res.data.list;
      });
    } else {
      this.getTable(0);
    }
    this.getCaseType();
    this.getPlaceType();
    this.getRoad();
  },
  mounted(){
    this.$bus.$on('selectMessageAlarm',(log_id)=>{
      getTable({
        id: log_id,
      }).then((res) => {
        this.alarmList = res.data.list;
      });
    })
  },
  destroyed(){
    this.$bus.$off('selectMessageAlarm')

  }
};
</script>

<style lang="scss" scoped>
.message_alarm {
  padding: 15px 15px 0;
}
</style>