


// 服务器地址
export const sever_url = 'https://cp.tongliao.rp.24xsjj.com'

// 资源服务器地址
export const qiniu_url = 'https://cp.tongliao.rp.24xsjj.com'

//IMSDK
export const imsdk=1400590453;


// // 服务器地址
// export const sever_url = 'https://cp.smartrp.rongac.com'

// // 资源服务器地址
// export const qiniu_url = 'https://cp.smartrp.rongac.com'

// //IMSDK
// export const imsdk=1400592178;