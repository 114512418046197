import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'

//引入初始化样式
import './assets/css/public.css'
import './assets/css/transition.scss'
import 'element-ui/lib/theme-chalk/display.css';
import './assets/css/rebuildElementUI.css'

//图片预览
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer);
Viewer.setDefaults({
  Options: { "inline": true, "button": true, "navbar": true, "title": true, "toolbar": true, "tooltip": true, "movable": true, "zoomable": true, "rotatable": true, "scalable": true, "transition": true, "fullscreen": true, "keyboard": true, "url": "data-source" }
});

//IM
import tim from './util/tim'
import TIM from 'tim-js-sdk';
//引入TRTC
import {createTrtcCalling} from './util/trtc'
import TRTCCalling from 'trtc-calling-js';

Vue.config.productionTip = false

Vue.prototype.tim = tim
Vue.prototype.TIM = TIM

Vue.prototype.$trtcCalling = createTrtcCalling();
Vue.prototype.TrtcCalling = TRTCCalling;
Vue.prototype.$bus=new Vue();

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
