<template>
  <div class="take_notes">
    <div class="notes" v-if="log_id">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-input v-model="person" placeholder="报警人"></el-input>
        </el-col>
        <el-col :span="8">
          <el-input v-model="phone" placeholder="报警人电话"></el-input>
        </el-col>
        <el-col :span="8">
          <el-input v-model="address" placeholder="地址"></el-input>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-select v-model="caseType" placeholder="警情分类">
            <el-option
              v-for="item in caseTypeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <el-select v-model="placeType" placeholder="场所类型">
            <el-option
              v-for="item in placeTypeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <el-select v-model="roadType" placeholder="区域">
            <el-option
              v-for="item in roadTypeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="20">
          <textarea placeholder="警情记录..." v-model="record"></textarea>
        </el-col>
        <el-col :span="4">
          <div class="btn" @click="closeSave(true)">
            <div class="btn_one">结束通话</div>
            <div>（并保存）</div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="noData" v-else>
      <img src="@/assets/images/home/noData/null_log_bottom.png" alt="" />
    </div>
  </div>
</template>

<script>
import {
  getCaseType, //获取警情分类
  getPlaceType, //获取场所列表
  getRoad, //获取路段标注
  setSmartrplog, //设置更新字段
} from "@/api/alarmRecord/alarmRecord";
import {
  stopQueue, //结束通话
} from "@/api/home/home";
export default {
  data() {
    return {
      person: "", //姓名
      phone: "", //电话
      address: "", //地址
      caseTypeList: [], //警情分类列表
      placeTypeList: [], //场所列表
      roadTypeList: [], //路段标注
      caseType: "", //警情分类
      placeType: "", //场所列表
      roadType: "", //路段标注
      log_id: "", //判断当前是否在聊天
      record: "", //警情记录
    };
  },
  methods: {
    closeSave(bol) {
      setSmartrplog({
        id: this.log_id,
        name: this.person,
        mobile: this.phone,
        caseaddress: this.address,
        casetype_id: this.caseType,
        placetype_id: this.placeType,
        road_id: this.roadType,
        quickrecord: this.record,
      }).then((res) => {
        if (bol) {
          stopQueue({ log_id: this.log_id }).then(
            (res) => {
              console.log('调用了stop');
            },
            (rej) => {
              this.$message.error(rej);
            }
          );
        }
      });
    },
    //获取警情分类
    getCaseType() {
      getCaseType().then((res) => {
        //console.log(res.data.list);
        this.caseTypeList = res.data.list;
      });
    },
    //获取场所列表
    getPlaceType() {
      getPlaceType().then((res) => {
        this.placeTypeList = res.data.list;
        console.log(res.data.list);
      });
    },
    //获取路段标注
    getRoad() {
      getRoad({
        city_id: this.$store.state.account.city_id,
      }).then((res) => {
        this.roadTypeList = res.data.list;
        console.log(res);
      });
    },
  },
  created() {
    this.getCaseType();
    this.getPlaceType();
    this.getRoad();
  },
  mounted() {
    this.$bus.$on("getNoteData", (item) => {
      this.person = item.log.name;
      this.phone = item.log.mobile;
      this.address = item.log.address;
      this.log_id = item.log_id;
    });
    this.$bus.$on("clearNoteData", () => {
      console.log("关闭按钮触发");
      this.closeSave(false);
      this.person = "";
      this.phone = "";
      this.address = "";
      this.log_id = "";
      this.record = "";
      this.caseType = "";
      this.placeType = "";
      this.roadType = "";
    });
  },
  destroyed() {
    this.$bus.$off("getNoteData");
    this.$bus.$off("clearNoteData");
  },
};
</script>

<style lang="scss" scoped>
.take_notes {
  margin-top: 2vh;
  height: 25vh;
  overflow: auto;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 4px 30px 0px rgba(244, 114, 93, 0.1);
  display: flex;
  flex-direction: column;
  .notes {
    padding: 24px 24px 0 24px;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-evenly;
    .el-input {
      margin-bottom: 8px;
    }
    .el-select {
      margin-bottom: 8px;
      width: 100%;
      background: #f9f9f9;
      border-radius: 5px;
      .el-input__inner {
        background: #f9f9f9 !important;
      }
    }
    textarea {
      width: 100%;
      height: 85px;
      border: none;
      background: #f9f9f9;
      border-radius: 5px;
      padding: 10px;
      font-size: 13px;
      font-weight: 500;
      color: #606266;
      resize: none;
      margin-bottom: 8px;
      &:focus {
        outline: none;
      }
      &::-webkit-input-placeholder {
        color: #c0c4cc;
      }
    }
    .btn {
      width: 100%;
      height: 85px;
      text-align: center;
      background: #f4725d;
      border-radius: 5px;
      font-size: 15px;
      font-family: Source Han Sans CN, Source Han Sans CN-Medium;
      font-weight: 500;
      color: #ffffff;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      .btn_one {
        display: flex;
        justify-content: center;
        align-items: flex-end;
      }
      div {
        flex: 1;
      }
    }
  }
  .noData {
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    img {
      height: 100%;
    }
  }
}
</style>