<template>
  <div class="index">
    <el-row :gutter="20">
      <el-col
        :xs="24"
        :sm="24"
        :md="8"
        :lg="8"
        :xl="8"
        v-for="(pendingItem, index) in pendingList"
        :key="index"
      >
        <pending :pendingItem="pendingItem" />
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <alarm-call />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getObtain } from "@/api/alarmRecord/alarmRecord";

import pending from "@/components/index/pending/pending.vue";
import AlarmCall from "@/components/index/alarmCall/alarmCall.vue";
export default {
  components: { pending, AlarmCall },
  data() {
    return {
      pendingList: [
        {
          icon: require("@/assets/images/index/pending_video.png"),
          title: "待处理音视频报警",
          sum: 0,
          path: "/alarmRecord",
          className: "btn_green",
        },
        {
          icon: require("@/assets/images/index/pending_message.png"),
          title: "待处理留言报警",
          sum: 0,
          path: "/messageAlarm",
          className: "btn_blue",
        },
        {
          icon: require("@/assets/images/index/pending_mute.png"),
          title: "待处理静默报警",
          sum: 0,
          path: "/silentAlarm",
          className: "btn_red",
        },
      ],
      timer: "",
    };
  },
  methods: {
    getObtains(obj) {
      getObtain(obj).then((res) => {
        res.data.forEach((item) => {
          if (+item.type === 0 || +item.type === 1) {
            this.$set(
              this.pendingList[0],
              "sum",
              this.pendingList[0].sum + item.count
            );
          } else if (+item.type === 2) {
            this.$set(
              this.pendingList[1],
              "sum",
              this.pendingList[1].sum + item.count
            );
          } else if (+item.type === 3) {
            this.$set(
              this.pendingList[2],
              "sum",
              this.pendingList[2].sum + item.count
            );
          }
        });
        
      });
    },
  },
  created() {
    this.getObtains({
      field: "type",
      status: {
        0: "in",
        1: 0,
      },
    });
  },
};
</script>

<style lang="scss" scoped>
.index {
  height: 100%;
  .el-row {
    margin: 0 !important;
  }
}
</style>