<template>
  <div class="home">
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="4" :lg="6" :xl="6">
          <mine-video/>
          <help-person-map/>
      </el-col>
      <el-col :xs="24" :sm="24" :md="14" :lg="12" :xl="13">
        <help-person-video/>
        <take-notes/>
      </el-col>
      <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="5">
        <chat/>
        <connect-queue/>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import mineVideo from '@/components/home/mineVideo/mineVideo.vue';
import HelpPersonMap from '@/components/home/helpPersonMap/helpPersonMap.vue';
import HelpPersonVideo from '@/components/home/helpPersonVideo/helpPersonVideo.vue';
import ConnectQueue from '@/components/home/connectQueue/connectQueue.vue';
import Chat from '@/components/home/chat/chat.vue';
import TakeNotes from '@/components/home/tokeNotes/takeNotes.vue';
export default {
  components: { 
    mineVideo,
    HelpPersonMap,
    HelpPersonVideo,
    ConnectQueue,
    Chat,
    TakeNotes,
  },

}
</script>

<style lang="scss" scoped>
.home {
  width: 100%;

  .el-row{
    margin: 0 !important;
  }
}
</style>