<template>
  <div class="table">
    <div class="btn">
      <div class="btn_group">
        <div class="green" @click="statusClick(2)">已转入派警系统</div>
        <div class="blue" @click="statusClick(1)" Style="display:none">标记为派警中</div>
        <div class="yellow" @click="statusClick(0)">标记为未处理</div>
        <div class="red" @click="statusClick(-1)">标记为无效报警</div>
      </div>
      <!-- <div class="btns">批量处理</div> -->
    </div>
    <div class="table_div">
      <el-table
        ref="multipleTable"
        :data="alarmList"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        border
        stripe
      >
        <el-table-column type="selection" width="55" align="center">
        </el-table-column>
        <el-table-column label="序号" align="center" width="65">
          <template slot-scope="scope">{{ scope.row.id }}</template>
        </el-table-column>
        <el-table-column label="报警类型" align="center" width="105">
          <template slot-scope="scope">{{
            scope.row.type | alarmType
          }}</template>
        </el-table-column>
        <el-table-column label="报警时间" align="center" width="155">
          <template slot-scope="scope">{{
            scope.row.createtime | getTime
          }}</template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="报警人昵称"
          align="center"
          width="125"
        >
        </el-table-column>
        <el-table-column
          prop="mobile"
          label="联系电话"
          width="125"
          align="center"
        >
        </el-table-column>
        <el-table-column label="报警位置" align="center" show-overflow-tooltip>
          <template slot-scope="scope"
            ><div @click="addressClick(scope.row)" class="address_div">
              {{ scope.row.address }}
            </div></template
          >
        </el-table-column>
        <el-table-column
          label="通话时长"
          align="center"
          show-overflow-tooltip
          width="100"
        >
          <template slot-scope="scope"
            ><div>
              {{ scope.row.dialogcost | getMin }}
            </div></template
          >
        </el-table-column>
        <el-table-column
          prop="casetype.name"
          label="警情类型"
          align="center"
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="placetype.name"
          label="场所类型"
          align="center"
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="road.name"
          label="事发路段"
          align="center"
          show-overflow-tooltip
          width="100"
        >
        </el-table-column>
        <el-table-column label="是否封禁" align="center" show-overflow-tooltip width="100">
          <template slot-scope="scope">
              <div :class="scope.row.user.bantime*1000 >Date.parse(new Date())?'red_font':'green_font'">
                {{ scope.row.user.bantime | getBanTimeStatus }}
              </div>
          </template>
        </el-table-column>
        <el-table-column
          label="查看详情"
          show-overflow-tooltip
          align="center"
          width="240"
        >
          <template slot-scope="scope">
            <el-tooltip effect="dark" content="警情记录" placement="top">
              <el-button
                type="success"
                icon="el-icon-edit"
                size="mini"
                @click="recordClick(scope.row)"
                style="background: #24d3b0; border: #24d3b0"
              ></el-button>
            </el-tooltip>
            <el-tooltip effect="dark" content="截图" placement="top">
              <el-button
                type="primary"
                icon="el-icon-picture-outline"
                size="mini"
                @click="showPictureClick(scope.row)"
                style="background: #6eafff; border: #6eafff"
              ></el-button>
            </el-tooltip>
            <el-tooltip effect="dark" content="回放" placement="top">
              <el-button
                type="danger"
                icon="el-icon-video-play"
                size="mini"
                @click="showVideoClick(scope.row)"
                style="background: #ff8c39; border: #ff8c39"
              ></el-button>
            </el-tooltip>
            <el-tooltip effect="dark" content="设置黑名单" placement="top">
              <el-button
                type="success"
                icon="el-icon-circle-close"
                size="mini"
                @click="setTimeClick(scope.row)"
                style="background: #e75740; border: #e75740"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog title="编辑记录" :visible.sync="isShowUpdateAlarm" width="30%">
      <div class="forms">
        <div class="forms_item">
          <div class="title_div">报警人称谓：</div>
          <div class="input_div">
            <el-input
              v-model="updateAlarmForm.name"
              placeholder="请输入内容"
            ></el-input>
          </div>
        </div>
        <div class="forms_item">
          <div class="title_div">报警人电话：</div>
          <div class="input_div">
            <el-input
              v-model="updateAlarmForm.mobile"
              placeholder="请输入内容"
            ></el-input>
          </div>
        </div>
        <div class="forms_item">
          <div class="title_div">描述案发地址：</div>
          <div class="input_div">
            <el-input
              v-model="updateAlarmForm.caseaddress"
              placeholder="请输入内容"
            ></el-input>
          </div>
        </div>
        <div class="forms_area">
          <div class="title_div">警情记录：</div>
          <div class="input_div">
            <el-input
              v-model="updateAlarmForm.quickrecord"
              placeholder="请输入内容"
              type="textarea"
              resize="none"
            ></el-input>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="confrim" @click="editAlarmItem">确认</div>
        <div class="cancel" @click="isShowUpdateAlarm = false">取消</div>
      </div>
    </el-dialog>
    <el-dialog
      title="位置"
      :visible.sync="isShowMap"
      width="50%"
      @close="closeCallBack"
      center
    >
      <my-map :mapFormData="mapFormData" ref="mapRef" />
    </el-dialog>
    <el-dialog title="视频回放" :visible.sync="isShowVideo" width="60%" center @close="closeVideo">
      <div class="video_div">
        <div class="left">
          <div class="title">接警员视频</div>
          <video
            ref="policeVideoRef"
            controls
            controlsList="nodownload"
          ></video>
        </div>
        <div class="right">
          <div class="title">报警人视频</div>
          <video
            ref="personVideoRef"
            controls
            controlsList="nodownload"
          ></video>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="音频回放" :visible.sync="isShowAudio" width="30%" center @close="closeAudio">
      <div class="audio_div">
        <div class="top">
          <div class="title">接警员音频</div>
          <audio
            ref="policeAudioRef"
            src=""
            controls
            controlsList="nodownload"
          ></audio>
        </div>
        <div class="bottom">
          <div class="title">报警人音频</div>
          <audio
            ref="personAudioRef"
            src=""
            controls
            controlsList="nodownload"
          ></audio>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="音视频截图"
      :visible.sync="isShowCatPicture"
      width="30%"
      center
    >
      <div class="picture_div">
        <viewer :images="pictureFormData">
          <img
            v-for="(item, index) in pictureFormData"
            :src="qiniu_url + '/' + item"
            :key="index"
            @click="isShowCatPicture = false"
          />
        </viewer>
      </div>
    </el-dialog>
    <el-dialog
      title="设置黑名单"
      :visible.sync="isShowNoSay"
      width="400px"
      center
    >
      <div class="no_say_div">
        <div class="no_say_title">
          该用户将在下列时间自动解禁；
          <br />
          如想手动解禁，可直接将该时间设置为小于当前时间。
        </div>
        <div class="no_say_info">
          用户：{{ noSayFormData.noSayName }}
          <br />
          电话：{{ noSayFormData.noSayPhone }}
        </div>
        <div class="no_say_body">
          <el-date-picker
            v-model="noSayFormData.noSayTime"
            type="datetime"
            placeholder="选择日期时间"
            align="right"
            @change="timeChange"
          >
          </el-date-picker>
        </div>
        <div class="no_say_footer">
          <div class="no_say_confrim" @click="setTime">确认</div>
          <div class="no_say_cancel" @click="isShowNoSay = false">取消</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { timeSf } from "@/common/date.js";
import { setNoSayTime } from "@/api/alarmRecord/alarmRecord.js";
import { qiniu_url } from "@/config/index";
import myMap from "@/components/myMap/myMap.vue";
export default {
  components: { myMap },
  props: {
    alarmList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      multipleSelection: [],
      ids: "", //多选选择的项
      isShowUpdateAlarm: false, //是否显示修改页面
      isShowMap: false, //是否显示地图
      isShowVideo: false, //是否显示视频/音频
      isShowAudio: false, //是否显示音频文件
      isShowCatPicture: false, //是否显示截图文件
      isShowNoSay: false, //是否显示禁言
      //修改弹出框数据
      updateAlarmForm: {
        name: "",
        mobile: "",
        caseaddress: "",
        quickrecord: "",
        id: "", //需要编辑的id
      },
      //地图弹出框数据
      mapFormData: {
        avator: "",
        name: "",
        mobile: "",
        address: "",
        lat: 0,
        lng: 0,
      },
      //视频显示
      videoFormData: {
        recordfiles: "", //警察视角
        policerecordfiles: "", //用户视角
      },
      //图片文件
      pictureFormData: [], //图片文件
      noSayFormData: {
        noSayId: "",
        noSayTime: "", //禁言时间
        noSayName: "", //设置名字
        noSayPhone: "", //设置电话
      },
    };
  },
  filters: {
    alarmType(type) {
      if (type == 0) {
        return "视频报警";
      } else if (type == 1) {
        return "音频报警";
      } else if (type == 2) {
        return "留言报警";
      } else if (type == 3) {
        return "静默求助";
      }
    },
    getTime(time) {
      return timeSf(time * 1000);
    },
    getMin(time) {
      if (Math.floor(time / 60)) {
        return Math.floor(time / 60) + "分" + (time % 60) + "秒";
      }
      return (time % 60) + "秒";
    },
    getBanTimeStatus(time){
      if (time*1000 >Date.parse(new Date())) {
        return '封禁'
      }else{
        return '正常'
      }

    }
  },
  computed: {
    qiniu_url() {
      return qiniu_url;
    },
  },
  methods: {
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    statusClick(status) {
      if (!this.multipleSelection.length) {
        return this.$message.error("请先选择需要变更的数据");
      }
      this.ids = this.multipleSelection.map((item) => item.id).join(",");
      this.$emit("setAllPlog", this.ids, "status", status);
    },
    //点击编辑警情记录
    recordClick(item) {
      console.log(item);
      this.updateAlarmForm.id = item.id;
      this.updateAlarmForm.name = item.name;
      this.updateAlarmForm.mobile = item.mobile;
      this.updateAlarmForm.quickrecord = item.quickrecord;
      this.updateAlarmForm.caseaddress = item.caseaddress;

      this.isShowUpdateAlarm = true;
    },
    //编辑弹框点击确认按钮
    editAlarmItem() {
      //console.log(this.updateAlarmForm);
      this.$emit("editAlarmItem", this.updateAlarmForm);
      this.isShowUpdateAlarm = false;
    },
    //点击位置
    addressClick(item) {
      this.mapFormData = {
        avator: item.user ? item.user.avatar : "",
        id: item.id,
        name: item.name,
        mobile: item.mobile,
        address: item.address,
        lat: item.lat,
        lng: item.lng,
      };
      console.log(this.mapFormData);
      this.isShowMap = true;
      this.$nextTick(() => {
        this.$refs.mapRef.initMap(this.mapFormData.lat, this.mapFormData.lng);
      });
      //
    },
    closeCallBack() {
      this.$refs.mapRef.deleteMap();
    },
    //音视频点击按钮
    showVideoClick(item) {
      this.videoFormData = {
        recordfiles: item.recordfiles, //警察视角
        policerecordfiles: item.policerecordfiles, //用户视角
      };
      if (+item.type === 1) {
        this.isShowAudio = true;
        this.$nextTick(() => {
          this.$refs.policeAudioRef.src = this.videoFormData.recordfiles;
          this.$refs.personAudioRef.src = this.videoFormData.policerecordfiles;
        });

        return;
      }
      this.isShowVideo = true;
      this.$nextTick(() => {
        this.$refs.policeVideoRef.src = this.videoFormData.recordfiles;
        this.$refs.personVideoRef.src = this.videoFormData.policerecordfiles;
      });
      console.log(this.videoFormData);
    },
    //关闭视频
    closeVideo(){
      this.$refs.policeVideoRef.pause();
      this.$refs.personVideoRef.pause();
    },
    //关闭音频
    closeAudio(){
      this.$refs.policeAudioRef.pause();
      this.$refs.personAudioRef.pause();
    },
    //截图点击按钮
    showPictureClick(item) {
      if (item.quickimg === null) {
        return this.$message.error("当前选项未有截图!");
      }
      this.pictureFormData = item.quickimg.split(",");
      this.isShowCatPicture = true;
    },
    setTimeClick(item) {
      this.noSayFormData.noSayId = item.user.id;
      this.noSayFormData.noSayName = item.user.nickname;
      this.noSayFormData.noSayPhone = item.user.mobile;
      if (!item.user.bantime) {
        this.noSayFormData.noSayTime=Date.parse(new Date());
      }else{
        this.noSayFormData.noSayTime=item.user.bantime*1000;
      }
      this.isShowNoSay = true;
    },
    timeChange(time) {
      this.noSayFormData.noSayTime=Date.parse(time);
    },
    setTime() {
      setNoSayTime({
        bantime:this.noSayFormData.noSayTime/1000,
        id:this.noSayFormData.noSayId
      }).then(res=>{
        this.$emit('getNoSayTimeTable')
        this.$message.success(res.msg)
      })
      this.isShowNoSay = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  margin-top: 15px;
  width: 100%;
  min-height: 65vh;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 4px 30px 0px rgba(244, 114, 93, 0.1);
  .address_div {
    height: 100%;
    cursor: pointer;
    color: #409eff;
  }
  .video_div {
    height: 50vh;
    display: flex;
    justify-content: space-between;
    .left,
    .right {
      width: 48%;
      .title {
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      video {
        width: 100%;
        height: 45vh;
      }
    }
  }
  .audio_div {
    height: 30vh;
    display: flex;
    flex-direction: column;
    .top,
    .bottom {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .title {
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .picture_div {
    min-height: 200px;
    display: flex;
    flex-wrap: wrap;
    img {
      width: 150px;
      height: 150px;
    }
  }
  .no_say_div {
    height: 300px;
    .no_say_title {
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .no_say_info {
      margin-left: 10px;
      height: 72px;
    }
    .no_say_body {
      // height: 100px;

      display: flex;
      justify-content: center;
    }
    .no_say_footer {
      width: 100%;
      display: flex;
      align-items: center;
      height: 150px;
      justify-content: space-evenly;
      .no_say_confrim {
        width: 108px;
        height: 34px;
        text-align: center;
        line-height: 34px;
        background: #f4725d;
        border-radius: 5px;
        font-size: 18px;
        font-weight: 500;
        color: #ffffff;
        cursor: pointer;
      }
      .no_say_cancel {
        width: 108px;
        height: 34px;
        background: #bebebe;
        border-radius: 5px;
        font-size: 18px;
        font-weight: 500;
        color: #ffffff;
        text-align: center;
        line-height: 34px;
        cursor: pointer;
      }
    }
  }
  .btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-right: 15px;
    .btns {
      margin-left: 15px;
      width: 104px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      background: #f4725d;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
    }
  }
  .btn_group {
    height: 84px;
    margin-left: 15px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    .blue {
      margin-left: 10px;
      width: 132px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      background: #6fafff;
      border-radius: 5px;
      cursor: pointer;
    }
    .yellow {
      margin-left: 10px;
      width: 132px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      background: #ffa767;
      border-radius: 5px;
      cursor: pointer;
    }
    .green {
      margin-left: 10px;
      width: 146px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      background: #24d3b0;
      border-radius: 5px;
      cursor: pointer;
    }
    .red {
      margin-left: 10px;
      width: 146px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      background: #e75740;
      border-radius: 5px;
      cursor: pointer;
    }
  }
  .table_div {
    padding: 15px;
    min-height: 450px;
  }
  .forms {
    height: 350px;
    .forms_item {
      display: flex;
      height: 70px;
      padding: 0 10%;
      .title_div {
        width: 25%;
        display: flex;
        justify-content: left;
        align-items: center;
      }
      .input_div {
        width: 75%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .forms_area {
      display: flex;
      height: 150px;
      padding: 0 10%;
      .title_div {
        width: 25%;
        display: flex;
        justify-content: left;
        margin-top: 30px;
      }
      .input_div {
        width: 75%;
        display: flex;
        justify-content: center;
      }
    }
  }
  .footer {
    width: 100%;
    display: flex;
    align-items: center;
    height: 100px;
    justify-content: space-evenly;
    .confrim {
      width: 108px;
      height: 52px;
      text-align: center;
      line-height: 52px;
      background: #f4725d;
      border-radius: 5px;
      font-size: 18px;
      font-weight: 500;
      color: #ffffff;
      cursor: pointer;
    }
    .cancel {
      width: 108px;
      height: 52px;
      background: #bebebe;
      border-radius: 5px;
      font-size: 18px;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
      line-height: 52px;
      cursor: pointer;
    }
  }
}
.green_font{
  color: #24d3b0;

}
.red_font{
  color: #e75740;
}
</style>