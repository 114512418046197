<template>
  <el-col :span="12">
    <div class="queue">
      <div class="queue_item">
        <div class="queue_img">
          <img src="@/assets/images/home/connect_queue/avator.png" alt="" />
        </div>
        <div class="queue_time">{{ (time - item.createtime) | getTime }}</div>
      </div>
    </div>
  </el-col>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      time: 0,
      timer: null,
    };
  },
  filters: {
    getTime(data) {
      if (data < 60) {
        return data + "秒";
      } else {
        return Math.floor(data / 60) + "分" + (data % 60) + "秒";
      }
    },
  },
  mounted() {
    this.time = Date.parse(new Date()) / 1000;
    console.log(this.time, "我的时间");
    console.log(this.item.createtime, "传过来的时间");
    this.timer = setInterval(() => {
      this.time = Date.parse(new Date()) / 1000;
    }, 1000);
  },
  destroyed() {
    clearInterval(this.timer);
  },
};
</script>

<style  lang="scss" scoped>
.queue {
  display: flex;
  justify-content: center;
  align-items: center;
}
.queue_item {
  width: 130px;
  height: 150px;
  .queue_img {
    width: 130px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f9f9f9;

    img {
      width: 60px;
      height: 60px;
    }
  }
  .queue_time {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    color: #707070;
  }
}
</style>