<template>
  <div class="help_person_map">
    <div class="map_top">
      <i class="el-icon-location-information icon"></i>
      <div class="map_title">
        {{ center }}
      </div>
    </div>
    <div id="map"></div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      center: "",
      lat:0,
      lng:0,
      map:'',
    };
  },
  methods: {
    //地图初始化函数，本例取名为init，开发者可根据实际情况定义
    initMap() {
      //定义地图中心点坐标
      console.log(window);
      var center = new window.TMap.LatLng(this.lat, this.lng);
      //定义map变量，调用 TMap.Map() 构造函数创建地图
      this.map = new TMap.Map(document.getElementById("map"), {
        center: center, //设置地图中心点坐标
        zoom: 17, //设置地图缩放级别
        viewMode: "2D",
      });
      var marker = new TMap.MultiMarker({
        map: this.map,
        styles: {
          // 点标记样式
          marker: new TMap.MarkerStyle({
            width: 10, // 样式宽
            height: 10, // 样式高
            anchor: { x: 0, y: 0 }, // 描点位置
            src: "https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerDefault.png", // 标记路径
          }),
        },
        geometries: [
          // 点标记数据数组
          {
            // 标记位置(经度，纬度，高度)
            position: new TMap.LatLng(this.lat, this.lng),
          },
        ],
      });
    },
  },
  mounted() {
    this.$bus.$on('helpPersonMapData',(item)=>{
      this.center=item.log.address
      this.lat=item.log.lat
      this.lng=item.log.lng
      console.log(this.lat,this.lng);
      this.map.destroy();
      this.initMap();
    })
     this.$bus.$on('clearPersonMapData',(item)=>{
      this.center=''
      this.lat=0
      this.lng=0
      this.map.destroy();
      this.initMap();

    })
    setTimeout(() => {
      this.initMap();
    }, 300);
  },
  destroyed(){
    this.$bus.$off('helpPersonMapData')
    this.$bus.$off('clearPersonMapData')
  }
};
</script>

<style lang="scss" scoped>
.help_person_map {
  margin-top: 2vh;
  height: 59vh;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 4px 30px 0px rgba(244, 114, 93, 0.1);
  display: flex;
  flex-direction: column;
  .map_top {
    height: 70px;
    padding: 15px 15px 0 15px;
    display: flex;
    .icon {
      font-size: 30px;
      color: #f4725d;
    }
    .map_title {
      margin-left: 5%;
      background: #f9f9f9;
      width: 85%;
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 500;
      color: #707070;
      padding-left: 10px;
    }
  }
  #map {
    margin: 15px;
    height: 85%;
  }
}
::v-deep #map {
  .rotate-circle,
  .tmap-zoom-control {
    display: none; // 缩放旋转控件半透明
  }
  .tmap-scale-control {
    display: none; // 隐藏比例尺
  }
  a {
    display: none; // 隐藏腾讯logo
  }
}
</style>