import Vue from 'vue'
import {Input,Button,MessageBox,Message,Row,Col,Container,Aside,Header,Main,Submenu,MenuItem,Menu,
        MenuItemGroup,Drawer,Badge,Dropdown,DropdownMenu,DropdownItem,Select,Option,Dialog,Form,FormItem,
        Notification,DatePicker,TimePicker,Table,TableColumn,Tooltip,Cascader,Pagination} from 'element-ui'



Vue.use(Input).use(Button).use(Row).use(Col).use(Container).use(Aside)
.use(Header).use(Main).use(Submenu).use(MenuItem).use(Menu)
.use(MenuItemGroup).use(Drawer).use(Badge).use(Dropdown).use(DropdownMenu)
.use(DropdownItem).use(Select).use(Option).use(Dialog).use(Form).use(FormItem)
.use(DatePicker).use(TimePicker).use(Table).use(TableColumn).use(Tooltip)
.use(Cascader).use(Pagination)



//消息提示框
Vue.prototype.$message=Message;
Vue.prototype.$confirm=MessageBox.confirm;
Vue.prototype.$notify=Notification;