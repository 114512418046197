
import axios from 'axios'
function request(config) {
  const instance = axios.create({
    baseURL: '',
    timeout: 5000
  })
  return instance(config)
}
export function getMenuData() {
  return request({
    url: 'data.json'
  })
}
export function getCity() {
  return request({
    url: 'city.json'
  })
}