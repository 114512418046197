<template>
  <div class="table">
    <div class="table_div">
      <el-table
        ref="multipleTable"
        :data="alarmList"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        border
        stripe
      >
        <el-table-column label="序号"  align="center" width="65">
          <template slot-scope="scope">{{ scope.row.id }}</template>
        </el-table-column>
        <el-table-column label="报警类型"  align="center" width="105">
          <template slot-scope="scope">
            {{
            scope.row.log.type | alarmType
          }}</template>
        </el-table-column>
        <el-table-column label="报警时间"  align="center" width="155">
          <template slot-scope="scope">{{
            scope.row.createtime | getTime
          }}</template>
        </el-table-column>
        <el-table-column
          prop="log.name"
          label="报警人昵称"
          align="center"
          width="125"
        >
        </el-table-column>
        <el-table-column
          prop="log.mobile"
          label="联系电话"
          width="125"
          align="center"
        >
        </el-table-column>
        <el-table-column
          label="报警位置"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope"
            ><div @click="addressClick(scope.row)" class="address_div">
              {{ scope.row.log.address }}
            </div></template
          >
        </el-table-column>
        <el-table-column
          label="连接单位组"
          align="center"
          show-overflow-tooltip
          width="100"
        >
          <template slot-scope="scope"
            ><div>
              {{ scope.row.log.city.name}}
            </div></template
          >
        </el-table-column>
        <el-table-column
          prop="log.ip"
          label="IP地址"
          align="center"
        >
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      title="位置"
      :visible.sync="isShowMap"
      width="50%"
      @close="closeCallBack"
      center
    >
      <my-map :mapFormData="mapFormData" ref="mapRef" />
    </el-dialog>
  </div>
</template>

<script>
import { timeSf } from "@/common/date.js";
import { qiniu_url } from "@/config/index";
import myMap from "@/components/myMap/myMap.vue";
export default {
  components: { myMap },
  props: {
    alarmList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      multipleSelection: [],
      ids: "", //多选选择的项
      isShowUpdateAlarm: false, //是否显示修改页面
      isShowMap: false, //是否显示地图
      isShowVideo: false, //是否显示视频/音频
      isShowAudio: false, //是否显示音频文件
      isShowCatPicture: false, //是否显示截图文件
      //修改弹出框数据
      updateAlarmForm: {
        name: "",
        mobile: "",
        caseaddress: "",
        quickrecord: "",
        id: "", //需要编辑的id
      },
      //地图弹出框数据
      mapFormData: {
        avator: "",
        name: "",
        mobile: "",
        address: "",
        lat: 0,
        lng: 0,
      },
      //视频显示
      videoFormData: {
        recordfiles: "", //警察视角
        policerecordfiles: "", //用户视角
      },
      //图片文件
      pictureFormData: [], //图片文件
    };
  },
  filters: {
    alarmType(type) {
      if (type == 0) {
        return "视频报警";
      } else if (type == 1) {
        return "音频报警";
      } else if (type == 2) {
        return "留言报警";
      } else if (type == 3) {
        return "静默求助";
      }
    },
    getTime(time) {
      return timeSf(time * 1000);
    },
    getMin(time){
      if(Math.floor(time/60)){
        return Math.floor(time/60)+'分'+time%60+'秒'
      }
      return time%60+'秒'
      
    }
  },
  computed: {
    qiniu_url() {
      return qiniu_url;
    },
  },
  methods: {
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    statusClick(status) {
      if (!this.multipleSelection.length) {
        return this.$message.error("请先选择需要变更的数据");
      }
      this.ids = this.multipleSelection.map((item) => item.id).join(",");
      this.$emit("setAllPlog", this.ids, "status", status);
    },
    //点击编辑警情记录
    recordClick(item) {
      console.log(item);
      this.updateAlarmForm.id = item.id;
      this.updateAlarmForm.name = item.name;
      this.updateAlarmForm.mobile = item.mobile;
      this.updateAlarmForm.quickrecord = item.quickrecord;
      this.updateAlarmForm.caseaddress = item.caseaddress;

      this.isShowUpdateAlarm = true;
    },
    //编辑弹框点击确认按钮
    editAlarmItem() {
      //console.log(this.updateAlarmForm);
      this.$emit("editAlarmItem", this.updateAlarmForm);
      this.isShowUpdateAlarm = false;
    },
    //点击位置
    addressClick(item) {
      this.mapFormData = {
        avator: item.log.user ? item.log.user.avatar:'',
        id: item.id,
        name: item.log.name,
        mobile: item.log.mobile,
        address: item.log.address,
        lat: item.log.lat,
        lng: item.log.lng,
      };
      console.log(this.mapFormData);
      this.isShowMap = true;
      this.$nextTick(() => {
        this.$refs.mapRef.initMap(this.mapFormData.lat, this.mapFormData.lng);
      });
      //
    },
    closeCallBack() {
      this.$refs.mapRef.deleteMap();
    },
    //音视频点击按钮
    showVideoClick(item) {
      this.videoFormData = {
        recordfiles: item.recordfiles, //警察视角
        policerecordfiles: item.policerecordfiles, //用户视角
      };
      if (+item.type === 1) {
        this.isShowAudio = true;
        this.$nextTick(() => {
          this.$refs.policeAudioRef.src = this.videoFormData.recordfiles;
          this.$refs.personAudioRef.src = this.videoFormData.policerecordfiles;
        });

        return;
      }
      this.isShowVideo = true;
      this.$nextTick(() => {
        this.$refs.policeVideoRef.src = this.videoFormData.recordfiles;
        this.$refs.personVideoRef.src = this.videoFormData.policerecordfiles;
      });
      console.log(this.videoFormData);
    },
    //截图点击按钮
    showPictureClick(item) {
      if (item.quickimg === null) {
        return this.$message.error("当前选项未有截图!");
      }
      this.pictureFormData = item.quickimg.split(",");
      this.isShowCatPicture = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  margin-top: 15px;
  width: 100%;
  min-height: 90vh;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 4px 30px 0px rgba(244, 114, 93, 0.1);
  .address_div {
    height: 100%;
    cursor: pointer;
    color: #409eff;
  }
  .video_div {
    height: 50vh;
    display: flex;
    justify-content: space-between;
    .left,
    .right {
      width: 48%;
      .title {
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      video {
        width: 100%;
        height: 45vh;
      }
    }
  }
  .audio_div {
    height: 30vh;
    display: flex;
    flex-direction: column;
    .top,
    .bottom {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .title {
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .picture_div {
    min-height: 200px;
    display: flex;
    flex-wrap: wrap;
    img {
      width: 150px;
      height: 150px;
    }
  }
  .btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-right: 15px;
    .btns {
      margin-left: 15px;
      width: 104px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      background: #f4725d;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
    }
  }
  .btn_group {
    height: 84px;
    margin-left: 15px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    .blue {
      margin-left: 10px;
      width: 132px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      background: #6fafff;
      border-radius: 5px;
      cursor: pointer;
    }
    .yellow {
      margin-left: 10px;
      width: 132px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      background: #ffa767;
      border-radius: 5px;
      cursor: pointer;
    }
    .green {
      margin-left: 10px;
      width: 146px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      background: #24d3b0;
      border-radius: 5px;
      cursor: pointer;
    }
    .red {
      margin-left: 10px;
      width: 146px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      background: #e75740;
      border-radius: 5px;
      cursor: pointer;
    }
  }
  .table_div {
    padding: 15px;
    min-height: 450px;
  }
  .forms {
    height: 350px;
    .forms_item {
      display: flex;
      height: 70px;
      padding: 0 10%;
      .title_div {
        width: 25%;
        display: flex;
        justify-content: left;
        align-items: center;
      }
      .input_div {
        width: 75%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .forms_area {
      display: flex;
      height: 150px;
      padding: 0 10%;
      .title_div {
        width: 25%;
        display: flex;
        justify-content: left;
        margin-top: 30px;
      }
      .input_div {
        width: 75%;
        display: flex;
        justify-content: center;
      }
    }
  }
  .footer {
    width: 100%;
    display: flex;
    align-items: center;
    height: 100px;
    justify-content: space-evenly;
    .confrim {
      width: 108px;
      height: 52px;
      text-align: center;
      line-height: 52px;
      background: #f4725d;
      border-radius: 5px;
      font-size: 18px;
      font-weight: 500;
      color: #ffffff;
      cursor: pointer;
    }
    .cancel {
      width: 108px;
      height: 52px;
      background: #bebebe;
      border-radius: 5px;
      font-size: 18px;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
      line-height: 52px;
      cursor: pointer;
    }
  }
}
</style>