<template>
  <div id="app" >
    <router-view></router-view>
    <model ref="modelRef" />
  </div>
</template>

<script>
//引入TRTC
import { getQueue, stopQueue } from "./api/home/home";
import { logout } from "./api/login/login";
import { getsuretransfer, canceltransfer } from "./api/alarmRecord/alarmRecord";
import model from "./components/model/model.vue";
import { Loading } from 'element-ui';
export default {
  components: { model },
  name: "app",
  data() {
    return {
      loading: "",
    };
  },
  mounted() {
    //TrTc启动监听
    this.initTrtcListener();
    //IM启动监听
    this.timListener();
    this.$bus.$on("loadingApp", (bol) => {
      if (bol) {
        this.loading = Loading.service({
          lock: true,
          text: "正在接通中......",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
      }else{
        this.loading.close();
      }
    });
  },
  methods: {
    // IM监听
    timListener() {
      console.log("打开监听");
      // 登录成功后会触发 SDK_READY 事件，该事件触发后，可正常使用 SDK 接口
      this.tim.on(this.TIM.EVENT.SDK_READY, this.onReadyStateUpdate, this);
      // 收到新消息
      this.tim.on(this.TIM.EVENT.MESSAGE_RECEIVED, this.onReceiveMessage);
      // 登出
      this.tim.on(this.TIM.EVENT.KICKED_OUT, this.onKickOut);
      // SDK内部出错
      this.tim.on(this.TIM.EVENT.ERROR, this.onError);
      // 收到 SDK 进入 not ready 状态通知，此时 SDK 无法正常工作
      // event.name - TIM.EVENT.SDK_NOT_READY
      this.tim.on(this.TIM.EVENT.SDK_NOT_READY, function (event) {
        this.$message.error("您的账号在 not ready，请退出重新登录");
        this.$notify({
          title: "提示",
          message: "您的账号在 not ready，请退出重新登录",
          duration: 0,
        });
      });
    },
    // tim准备就绪
    onReadyStateUpdate({ name }) {
      console.log(123);
      const isSDKReady = name === this.TIM.EVENT.SDK_READY ? true : false;
      this.$store.commit("setTimReady", isSDKReady);
      if (isSDKReady) {
      }
    },
    // 收到新消息
    onReceiveMessage({ data: messageList }) {
      console.log(messageList, 123456);
      if (
        messageList[0].type == "TIMCustomElem" &&
        messageList[0].payload.description == "k_diy"
      ) {
        const systemMsg = JSON.parse(messageList[0].payload.data);

        if (systemMsg.code == "1") {
          //data为1，链接成功
          this.$notify.closeAll();
          const queueItem = JSON.parse(sessionStorage.getItem("queueItem"));
          console.log(queueItem);
          this.$store.commit("setLogId", queueItem.log_id);
          this.$refs.modelRef.isShowMsg = false;
          if (localStorage.getItem("openCamera")) {
            this.$store.commit("setCamera", false);
          } else {
            this.$store.commit("setCamera", true);
          }
          this.$bus.$emit("helpPersonMapData", queueItem);
          this.$bus.$emit("getImData", queueItem);
          this.$bus.$emit("getNoteData", queueItem);
          this.$bus.$emit("getVideoData", queueItem);
          this.$bus.$emit("getmyVideoData", queueItem);
          //this.$bus.$emit("openDevice");
          sessionStorage.removeItem("queueItem");
          return;
        } else if (systemMsg.code == "2") {
          //data为2，拒绝链接
          return;
        } else if (systemMsg.code == "3") {
          sessionStorage.setItem("isCall", "0");
          this.$trtcCalling.hangup();
          this.$refs.modelRef.isShowMsg = true;
          //data为3，结束音视频
          this.$store.commit("setCamera", false);
          this.$store.commit("setMicMute", false);
          this.$store.commit("deleteMessage");
          this.$store.commit("setLogId", "");
          this.$bus.$emit("clearPersonMapData");
          this.$bus.$emit("clearImData");
          this.$bus.$emit("clearNoteData");
          this.$bus.$emit("clearVideoData");
          this.$bus.$emit("clearmyVideoData");

          return;
        } else if (systemMsg.code == "4") {
          //data为4，实现转接
          getQueue({
            id: systemMsg.data.qid,
            status: 1,
          }).then((res) => {
            this.$confirm("有一个转接邀约请求,您是否接听?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                getsuretransfer({ log_id: res.data.list[0].log_id }).then(
                  () => {
                    sessionStorage.setItem(
                      "queueItem",
                      JSON.stringify(res.data.list[0])
                    );
                  }
                );
                this.$message({
                  type: "success",
                  message: "接听成功，请稍等!",
                });
              })
              .catch(() => {
                canceltransfer({ log_id: res.data.list[0].log_id }).then(
                  (res) => {}
                );
                this.$message({
                  type: "info",
                  message: "接听失败",
                });
              });
          });
        } else if (systemMsg.code == "10") {
          console.log(systemMsg);
          this.$bus.$emit("getModelData", systemMsg.data);
          this.$bus.$emit("setstatusChange"); //重置左侧导航栏气泡数
        }
      }
      if (messageList[0].type != "TIMCustomElem") {
        //把新发信息添加到信息列表里面
        this.$store.commit("pushCurrentMessageList", messageList);
      }
    },
    // 登出
    onKickOut(event) {
      //this.$message.error("您的账号在其他设备上登录，请退出重新登录");
      if (
        event.data.type === "multipleAccount" ||
        event.data.type === "multdevice"
      ) {
        console.log("同一账号，多端登录被踢", event);
        if (sessionStorage.getItem("isCall") === "1") {
          stopQueue({ log_id: this.$store.state.conversation.log_id }).then(
            (res) => {
              this.$refs.modelRef.isShowMsg = true;
              logout().then((res) => {
                sessionStorage.clear();
                this.$store.dispatch("logout");
                this.$router.push("/login");
              });
              this.$message.error("您的账号在其他设备上登录，请退出重新登录");
            }
          );
          return;
        }
        logout().then((res) => {
          sessionStorage.clear();
          this.$store.dispatch("logout");
          this.$router.push("/login");
        });
        this.$message.error("您的账号在其他设备上登录，请退出重新登录");
      }
    },
    //IM内部报错
    onError({ data }) {
      if (data.message !== "Network Error") {
        console.log(data);
      }
    },
    onSDKReady(res) {
      console.log(res);
    },
    //您可以通过监听 EVENT 里的 ERROR 字段，对组件抛出的错误进行处理，
    handleError(error) {
      console.log(error);

      if (error.code === 60005) {
        this.$message.error("	用户禁止使用设备,请清除缓存后重新授权设备");
      } else if (error.code === 60003) {
        this.$message.error("	没有可用的麦克风设备");
      } else if (error.code === 60004) {
        this.$message.error("	没有可用的摄像头设备");
      }
      stopQueue({ log_id: this.$store.state.conversation.log_id }).then(
        (res) => {
          console.log(123456);
          this.$refs.modelRef.isShowMsg = true;
        }
      );
    },
    // 被邀方 	收到了邀请通知
    //收到邀请通知。
    //触发条件：被邀请方已再另一通话中，发起方收到 LINE_BUSY 事件回调。
    handleNewInvitationReceived(event) {
      console.log("接电话");
      console.log(event);
    },
    //用户拒绝通话。
    //触发条件：被邀请方拒绝通话，发起方收到 REJECT 事件回调。
    handleInviteeReject(event) {
      console.log(event);
      this.$message.error("对方拒绝了电话");
    },
    //用户接听
    handleUserAccept(event) {
      const { userID } = event;
      console.log(event, "用户接听了");
    },
    //邀请方和被邀方   用户进房
    //用户进房。
    //触发条件：当有用户进入通话。
    handleUserEnter(event) {
      console.log(event);
      console.log("用户进入房间");
      this.$bus.$emit("openMyVideo");
      this.$bus.$emit("openDevice");
      sessionStorage.setItem("isCall", "1");
      this.$refs.modelRef.isShowMsg = false;
      this.loading.close();
    },
    //邀请方和被邀方   有用户离开通话
    //用户退出房间。
    //触发条件：当有用户退出通话。。
    handleUserLeave(event) {
      this.$message.error("用户已经退出房间!");
      console.log(event);
      sessionStorage.setItem("isCall", "0");
      this.$refs.modelRef.isShowMsg = true;
    },
    //被邀请方正在通话中，忙线。
    //触发条件：被邀请方已再另一通话中，发起方收到 LINE_BUSY 事件回调。
    handleInviteeLineBusy(event) {
      console.log("用户退出房间");
    },
    //重复登录，被踢出房间
    //触发条件：在其他页面重复登录。
    handleKickedOut(event) {
      console.log("trtc被踢");
    },
    //本次通话结束。
    //触发条件：结束本次通话。
    handleCallEnd(event) {
      console.log(event);
      console.log("结束本次通话");
    },
    //邀请用户无应答。
    //触发条件：当 call/groupCall 设置 timeout，被邀请方未在 timeout 内未在接听，发起方收到 NO_RESP 事件回调
    handleNoResponse({ userID, userIDList }) {
      console.log(userID, userIDList);
      this.$message.error("邀请用户无应答");
      this.loading.close();
    },
    //本次通话被取消了。
    //触发条件：发起方在呼叫过程中取消通话，被邀请方收到 CALLING_CANCEL 事件回调。
    handleInviterCancel(event) {
      console.log(event);
    },
    //本次通话超时未应答。
    //触发条件：当 call/groupCall 设置 timeout，被邀请方未在 timeout 内未在接听，被邀请方收到 CALLING_TIMEOUT 事件回调。
    handleCallTimeout(event) {
      console.log(event);
    },
    //远端用户开启/关闭了麦克风。
    //触发条件：远端用户开启/关闭了麦克风。
    handleUserVideoChange(event) {
      console.log(event);
    },
    //远端用户开启/关闭了麦克风。
    //触发条件：远端用户开启/关闭了麦克风。
    handleUserAudioChange(event) {
      console.log(event);
    },
    // //TRTC监听初始化
    initTrtcListener() {
      console.log("trtc开始监听");
      this.$trtcCalling.on(this.TrtcCalling.EVENT.ERROR, this.handleError);
      this.$trtcCalling.on(
        this.TrtcCalling.EVENT.INVITED,
        this.handleNewInvitationReceived
      );
      this.$trtcCalling.on(
        this.TrtcCalling.EVENT.USER_ACCEPT,
        this.handleUserAccept
      );
      this.$trtcCalling.on(
        this.TrtcCalling.EVENT.USER_ENTER,
        this.handleUserEnter
      );
      this.$trtcCalling.on(
        this.TrtcCalling.EVENT.USER_LEAVE,
        this.handleUserLeave
      );
      this.$trtcCalling.on(
        this.TrtcCalling.EVENT.REJECT,
        this.handleInviteeReject
      );
      this.$trtcCalling.on(
        this.TrtcCalling.EVENT.LINE_BUSY,
        this.handleInviteeLineBusy
      );
      this.$trtcCalling.on(
        this.TrtcCalling.EVENT.CALLING_CANCEL,
        this.handleInviterCancel
      );
      this.$trtcCalling.on(
        this.TrtcCalling.EVENT.KICKED_OUT,
        this.handleKickedOut
      );
      this.$trtcCalling.on(
        this.TrtcCalling.EVENT.CALLING_TIMEOUT,
        this.handleCallTimeout
      );
      this.$trtcCalling.on(
        this.TrtcCalling.EVENT.NO_RESP,
        this.handleNoResponse
      );
      this.$trtcCalling.on(
        this.TrtcCalling.EVENT.CALLING_END,
        this.handleCallEnd
      );
      this.$trtcCalling.on(
        this.TrtcCalling.EVENT.USER_VIDEO_AVAILABLE,
        this.handleUserVideoChange
      );
      this.$trtcCalling.on(
        this.TrtcCalling.EVENT.USER_AUDIO_AVAILABLE,
        this.handleUserAudioChange
      );
      this.$trtcCalling.on(this.TrtcCalling.EVENT.SDK_READY, this.onSDKReady);
    },

    //删除监听
    removeListener() {
      this.$trtcCalling.off(this.TrtcCalling.EVENT.ERROR, this.handleError);
      this.$trtcCalling.off(
        this.TrtcCalling.EVENT.INVITED,
        this.handleNewInvitationReceived
      );
      this.$trtcCalling.off(
        this.TrtcCalling.EVENT.REJECT,
        this.handleInviteeReject
      );
      this.$trtcCalling.off(
        this.TrtcCalling.EVENT.USER_ACCEPT,
        this.handleUserAccept
      );
      this.$trtcCalling.off(
        this.TrtcCalling.EVENT.USER_ENTER,
        this.handleUserEnter
      );
      this.$trtcCalling.off(
        this.TrtcCalling.EVENT.USER_LEAVE,
        this.handleUserLeave
      );
      this.$trtcCalling.off(
        this.TrtcCalling.EVENT.LINE_BUSY,
        this.handleInviteeLineBusy
      );
      this.$trtcCalling.off(
        this.TrtcCalling.EVENT.CALLING_CANCEL,
        this.handleInviterCancel
      );
      this.$trtcCalling.off(
        this.TrtcCalling.EVENT.KICKED_OUT,
        this.handleKickedOut
      );
      this.$trtcCalling.off(
        this.TrtcCalling.EVENT.CALLING_TIMEOUT,
        this.handleCallTimeout
      );
      this.$trtcCalling.off(
        this.TrtcCalling.EVENT.NO_RESP,
        this.handleNoResponse
      );
      this.$trtcCalling.off(
        this.TrtcCalling.EVENT.CALLING_END,
        this.handleCallEnd
      );
      this.$trtcCalling.off(
        this.TrtcCalling.EVENT.USER_VIDEO_AVAILABLE,
        this.handleUserVideoChange
      );
      this.$trtcCalling.off(
        this.TrtcCalling.EVENT.USER_AUDIO_AVAILABLE,
        this.handleUserAudioChange
      );
      // 登录成功后会触发 SDK_READY 事件，该事件触发后，可正常使用 SDK 接口
      this.tim.off(this.TIM.EVENT.SDK_READY, this.onReadyStateUpdate, this);
      // 收到新消息
      this.tim.off(this.TIM.EVENT.MESSAGE_RECEIVED, this.onReceiveMessage);
      // 登出
      this.tim.off(this.TIM.EVENT.KICKED_OUT, this.onKickOut);
      // SDK内部出错
      this.tim.off(this.TIM.EVENT.ERROR, this.onError);
    },
  },
  destroyed() {
    this.removeListener();
    this.$bus.$off('loadingApp')
  },
};
</script>

<style>
.el-icon-loading{
 font-size: 45px;
 color: #f4725d !important;
}
.el-loading-text{
  font-size: 20px !important;
  color: #f4725d !important;
}
</style>
