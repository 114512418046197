import { render, staticRenderFns } from "./silentAlarm.vue?vue&type=template&id=647bb44c&scoped=true&"
import script from "./silentAlarm.vue?vue&type=script&lang=js&"
export * from "./silentAlarm.vue?vue&type=script&lang=js&"
import style0 from "./silentAlarm.vue?vue&type=style&index=0&id=647bb44c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "647bb44c",
  null
  
)

export default component.exports