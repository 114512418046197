<template>
  <div :id="'myMap-' + mapFormData.id" class="myMap"></div>
</template>

<script>
import { qiniu_url } from "@/config/index";
export default {
  props: {
    mapFormData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      map: "",
    };
  },
  methods: {
    initMap() {
      //定义地图中心点坐标
      var center = new window.TMap.LatLng(
        this.mapFormData.lat,
        this.mapFormData.lng
      );
      //定义map变量，调用 TMap.Map() 构造函数创建地图
      this.map = new TMap.Map(
        document.getElementById("myMap-" + this.mapFormData.id),
        {
          center: center, //设置地图中心点坐标
          zoom: 17, //设置地图缩放级别
          viewMode: "2D",
        }
      );
      var infoWindowLocation = new TMap.LatLng(
        this.mapFormData.lat,
        this.mapFormData.lng
      ); //创建一个坐标
      //创建InfoWindow实例，并进行初始化
      var infoWindow = new TMap.InfoWindow({
        map: this.map,
        position: center,
        //设置infoWindow，content支持直接传入html代码，以实现各类内容格式需求
        content: `
        <div class="left">
          <img src="${this.mapFormData.avator ? qiniu_url + this.mapFormData.avator : require('@/assets/images/falseData/avator.png')}" alt="">
          <p>姓名:${this.mapFormData.name}</p>
          <p>电话:${this.mapFormData.mobile}</p>
          <p>地址:${this.mapFormData.address}</p>
        </div>
        `,
      });
    },
    deleteMap() {
      console.log(123);
      this.map.destroy();
    },
  },
};
</script>

<style>
.myMap {
  width: 100%;
  height: 500px;
  text-align: left;
}
.myMap p {
  text-align: left !important;
  width: 150px;
  word-wrap:break-word !important;
  white-space:pre-wrap
}
.myMap img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.myMap svg {
  display: none !important;
}
.myMap .rotate-circle,
.tmap-zoom-control,
.tmap-scale-control,
a {
  display: none;
}
</style>