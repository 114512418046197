<template>
  <div class="chat">
    <!-- v-if="currentMessageList.length" -->
    <div class="chat_top" v-if="log_id">
      <div class="chat_body" ref="messageListRef">
        <message-list :currentMessageList="currentMessageList" />
      </div>
      <chat-footer @toBottom="scrollMessageListToButtom" ref="chatFootRef"  />
    </div>
    <div class="noData" v-else>
      <img src="@/assets/images/home/noData/null_log_top.png" alt="">
    </div>
  </div>
</template>

<script>
import ChatFooter from "./chatFooter/chatFooter.vue";
import messageList from "./messageList/messageList.vue";
import { mapState } from "vuex";
export default {
  data(){
    return {
    
    }
  },
  components: {
    messageList,
    ChatFooter,
  },
  computed: {
    ...mapState({
      currentMessageList: (state) => state.conversation.currentMessageList,
      log_id: (state) => state.conversation.log_id,
    }),
  },
  methods: {
    scrollMessageListToButtom() {
      this.$nextTick(() => {
        let messageListNode = this.$refs["messageListRef"];
        if (!messageListNode) {
          return;
        }
        messageListNode.scrollTop = messageListNode.scrollHeight;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.chat {
  margin-top: 2vh;
  height: 35vh;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 4px 30px 0px rgba(244, 114, 93, 0.1);
  display: flex;
  flex-direction: column;
  .chat_top {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .chat_body {
    flex: 1;
    overflow-y: scroll;
    position: relative;
    padding: 0 16px;
    transition: 0.5s;
  }
  .noData{
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
  }
}
</style>