import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style

import mainHomeTemp from '../views/mainHomeTemp/mainHomeTemp.vue'

import login from '../views/login/login.vue'

//首页
import Index from '../views/index/index.vue'
//音视频报警页
import Home from '../views/home/home.vue'
//报警记录
import alarmRecord from '../views/alarmRecord/alarmRecord.vue'
import silentAlarm from '../views/silentAlarm/silentAlarm.vue'
import messageAlarm from '../views/messageAlarm/messageAlarm.vue'
import missedCalls from '../views/missedCalls/missedCalls.vue'

Vue.use(VueRouter)
const routes = [
  { path: '/', redirect: '/login' },
  
  {
    path: '/login',
    name: 'login',
    component: login,
    meta: {
      title: '登录'
    },
  },
  {
    path: '/mainHomeTemp',
    name: 'mainHomeTemp',
    component: mainHomeTemp,
    meta: {
      title: '首页'
    },
    redirect: '/index',
    children: [
      {
        path: '/index',//首页
        component: Index,
        meta: {
          title: '首页'
        },
      },
      {
        path: '/home',//音视频报警页
        component: Home,
        meta: {
          title: '音视频报警页'
        },
      },
      {
        path: '/alarmRecord',//报警记录
        component: alarmRecord,
        meta: {
          title: '报警记录'
        },
      },
      {
        path: '/silentAlarm',//静默求助
        component: silentAlarm,
        meta: {
          title: '静默求助'
        },
      },
      {
        path: '/messageAlarm',//留言报警
        component: messageAlarm,
        meta: {
          title: '留言报警'
        },
      },
      {
        path: '/missedCalls',//留言报警
        component: missedCalls,
        meta: {
          title: '未接报警'
        },
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
//挂在路由导航守卫
router.beforeEach((to, from, next) => {
  NProgress.start()
  //to 将要访问的路径
  //from 代表从哪个路径来的
  //next 一个函数，是否放行
  if (from.path==="/home" && sessionStorage.getItem('isCall')==='1') {
    NProgress.done() 
    alert('请先关闭通话再切换页面!')
    return;
  }
  if (to.path === '/login') { 
    NProgress.done() 
    return next(); 
  }
  const tokenStr = window.sessionStorage.getItem('token');
  if (!tokenStr) { return next('/login') }
  next();
  NProgress.done()
})

export default router
